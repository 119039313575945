import { CSSProperties, FC } from "react";
import styles from "./CaseStudyTile.module.css";
import pageStyles from "../../Page/Page.module.css";
import { useNavigate } from "react-router";
import TileImagePlaceHolder from "./TileImagePlaceHolder";
import { IBaseComponentData } from "../../../config/DynamicConfigTypes";

export interface ICaseStudyTileData extends IBaseComponentData {
  img: string | null;
  title: string;
  subtitle: string;
  link: string;
  titleClassName?: string;
  titleStyle?: CSSProperties;
  subtitleClassName?: string;
  subtitleStyle?: CSSProperties;
  imgClassName?: string;
  imgStyle?: CSSProperties;
}
export interface ICaseStudyTileProps extends ICaseStudyTileData {
  className?: string;
  style?: CSSProperties;
}
const CaseStudyTile: FC<ICaseStudyTileProps> = ({
  className = "",
  style = {},
  img = null,
  title = "Title",
  subtitle = "Subtitle",
  link = "",
  titleClassName = pageStyles.p1,
  subtitleClassName = pageStyles.p3,
}) => {
  let image = <TileImagePlaceHolder />;
  if (img !== null) {
    image = <img src={img} alt="" className={[styles.Image].join(" ")} />;
  }

  const navigate = useNavigate();

  const onTileClick = () => {
    navigate("/" + link);
  };

  return (
    <div
      className={[styles.Tile, className].join(" ")}
      onClick={onTileClick}
      style={style}
    >
      {image}
      <div className={styles.MetaWrapper}>
        <div
          className={[titleClassName].join(" ")}
          dangerouslySetInnerHTML={{ __html: title }}
        ></div>
        <div
          className={[subtitleClassName].join(" ")}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        ></div>
      </div>
    </div>
  );
};

export default CaseStudyTile;
