import styles from "./Badge.module.css";

import { ReactComponent as AuxEnergy } from "../../assets/badges/AuxEnergy.svg";
import { ReactComponent as BayShore } from "../../assets/badges/Bayshore.svg";
import { ReactComponent as HomeRun } from "../../assets/badges/HomeRun.svg";
import { ReactComponent as HunterDouglas } from "../../assets/badges/HunterDouglas.svg";
import { ReactComponent as LordJones } from "../../assets/badges/LordJones.svg";
import { ReactComponent as Mawer } from "../../assets/badges/Mawer.svg";
import { ReactComponent as TheDrop } from "../../assets/badges/TheDrop.svg";
import { ReactComponent as UJA } from "../../assets/badges/UJA.svg";
import { ReactComponent as Vyta } from "../../assets/badges/Vyta.svg";
import { ReactComponent as Winery } from "../../assets/badges/Winery.svg";
import { Badges } from "../../config/models/Badges";

export interface IBadgeProps {
  className?: string;
  onClick?: () => void;
  badge: Badges;
  style?: React.CSSProperties;
}

const Badge: React.FC<IBadgeProps> = (props) => {
  let badge = null;
  switch (props.badge) {
    case Badges.AuxEnergy:
      badge = <AuxEnergy />;
      break;
    case Badges.BayShore:
      badge = <BayShore />;
      break;
    case Badges.HomeRun:
      badge = <HomeRun />;
      break;
    case Badges.HunterDouglas:
      badge = <HunterDouglas />;
      break;
    case Badges.LordJones:
      badge = <LordJones />;
      break;
    case Badges.Mawer:
      badge = <Mawer />;
      break;
    case Badges.TheDrop:
      badge = <TheDrop />;
      break;
    case Badges.UJA:
      badge = <UJA />;
      break;
    case Badges.Vyta:
      badge = <Vyta />;
      break;
    case Badges.Winery:
      badge = <Winery />;
      break;
  }
  return (
    <div
      onClick={props.onClick}
      className={`${props.className} ${styles.Badge}`}
      style={props.style}
    >
      {badge}
    </div>
  );
};
export default Badge;
export { Badges };
