import { MouseEvent } from "react";
import { useNavigate } from "react-router";

/**
 * A hook that will return a `React.MouseEventHandler` that will kill all link navigations from an element that has used `dangerouslySetInnerHtml`.
 * @returns {React.MouseEventHandler} the handler that will `preventDefault` on any `<a>` clicks.
 */
export const useCaptureLinkClicksFromHTML = () => {
  const navigate = useNavigate();
  return (e: MouseEvent) => {
    const targetLink = (e.target as HTMLElement).closest("a");
    if (!targetLink) return;
    const isRel =
      new URL(document.baseURI).origin ===
      new URL(targetLink.href, document.baseURI).origin;
    if (isRel) {
      e.preventDefault();
      const url = new URL(targetLink.href);
      const rel = url.toString().substring(url.origin.length);

      navigate(rel);
    }
  };
};
