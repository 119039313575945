import * as React from "react";
import styles from "./DividerHero.module.css";
import pageStyles from "../../Page/Page.module.css";
import { ReactComponent as Divider } from "../../../assets/Divider.svg";
import TextWithHeader, { ITextWithHeaderData } from "../../Page/TextWithHeader";

export interface IDividerHeroData extends ITextWithHeaderData {
  textWrapperClass?: string;
  textWrapperStyle?: React.CSSProperties;
}

export default function DividerHero(props: IDividerHeroData) {
  return (
    <div
      className={[props.className, styles.DividerHero].join(" ")}
      style={props.style}
    >
      <section className={styles.Left}>
        <Divider className={[pageStyles.Divider, styles.Divider].join(" ")} />
        <Divider className={[pageStyles.Divider, styles.Divider].join(" ")} />
        <Divider className={[pageStyles.Divider, styles.Divider].join(" ")} />
        <Divider className={[pageStyles.Divider, styles.Divider].join(" ")} />
      </section>
      <section className={styles.Right}>
        <TextWithHeader
          {...props}
          className={props.textWrapperClass}
          style={props.textWrapperStyle}
        />
      </section>
    </div>
  );
}
