import { CSSProperties, FC, useState } from "react";
import ImageHero, { IImageHeroData } from "../ImageHero";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./yarl-overrides.css";

import styles from "./ImageHeroPopUp.module.css";

export interface IImageHeroPopUpData extends IImageHeroData {
  imageHeroClass?: string;
  imageHeroStyle?: CSSProperties;
  popupLabel?: string;
  popupClass?: string;
  popupStyle?: CSSProperties;
  popupSrc?: string;
  popupAlt?: string;
}

const ImageHeroPopUp: FC<IImageHeroPopUpData> = (props) => {
  const [_isOpen, _setIsOpen] = useState(false);
  const imageProps: IImageHeroData = {
    className: props.imageHeroClass,
    style: props.imageHeroStyle,
    src: props.src,
    alt: props.alt,
    headerContent: props.headerContent,
    headerContentClass: props.headerContentClass,
    headerContentStyle: props.headerContentStyle,
    subHeaderContent: props.headerContent,
    subHeaderClass: props.subHeaderClass,
    subHeaderStyle: props.subHeaderStyle,
    textContent: props.textContent,
    textContentClass: props.textContentClass,
    textContentStyle: props.textContentStyle,
    textWrapperClass: props.textWrapperClass,
  };

  const lookInsideElement = [
    <div
      key="look-inside-key"
      className={[styles.PopUpLink, props.popupClass].join(" ")}
      style={{
        ...props.popupStyle,
      }}
      onClick={() => _setIsOpen(true)}
    >
      {props.popupLabel}
    </div>,
  ];
  return (
    <section className={[styles.Wrapper, props.className].join(" ")}>
      <Lightbox
        open={_isOpen}
        plugins={[Zoom]}
        close={() => _setIsOpen(false)}
        slides={[
          {
            src: props.popupSrc || "",
            imageFit: "contain",
          },
        ]}
      />
      <ImageHero
        {...imageProps}
        extraImgElements={lookInsideElement}
        imgClassWrapper={[props.imgClassWrapper, styles.LookInsideWrapper].join(
          " "
        )}
        onImageClick={() => _setIsOpen(true)}
      />
    </section>
  );
};

export default ImageHeroPopUp;
