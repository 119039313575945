import { CSSProperties, FunctionComponent } from "react";
import TextWithHeader, { ITextWithHeaderData } from "../Page/TextWithHeader";
import styles from "./ImageHero.module.css";

export interface IImageHeroData extends ITextWithHeaderData {
  src: string;
  alt?: string;
  imgClass?: string;
  imgStyle?: CSSProperties;
  imgClassWrapper?: string;
  textWrapperClass?: string;
  textWrapperStyle?: CSSProperties;
}

export interface IImageHeroProps extends IImageHeroData {
  extraImgElements?: JSX.Element[];
  onImageClick?: () => void;
}

const ImageHero: FunctionComponent<IImageHeroProps> = (props) => {
  const imageHeroClass = [styles.ImageHero, props.className || ""].join(" ");

  const textProps: ITextWithHeaderData = {
    textContent: props.textContent,
    className: props.textWrapperClass,
    headerContent: props.headerContent,
    headerContentClass: props.headerContentClass,
    headerContentStyle: props.headerContentStyle,
    style: props.textWrapperStyle,
    textContentClass: props.textContentClass,
    textContentStyle: props.textWrapperStyle,
  };

  return (
    <div className={imageHeroClass} style={props.style}>
      <div className={props.imgClassWrapper || ""}>
        {props.src === "" ? null : (
          <img
            src={props.src}
            alt={props.alt || ""}
            className={[props.imgClass || styles.Image].join(" ")}
            onClick={props.onImageClick}
          />
        )}
        {props.extraImgElements}
      </div>
      <TextWithHeader {...textProps} />
    </div>
  );
};

export default ImageHero;
