import { CSSProperties, FC, useRef, useState } from "react";

import styles from "./CaseStudyFilter.module.css";
import pageStyles from "../Page/Page.module.css";
import { ReactComponent as Arrow } from "../../assets/DownArrow.svg";
import {
  CaseStudyFilterOption,
  ICaseStudyFilterOptionProps,
} from "./CaseStudyFilterOption";
import { ServiceIcons } from "../../config/models/Services";

export interface ICaseStudyFilterProps {
  wrapperClassName?: string;
  headerText?: string;
  headerClassName?: string;
  headerStyle?: CSSProperties;
  filterLabelText?: string;
  filterLabelClassName?: string;
  filterLabelStyle?: CSSProperties;
  filterOptions?: ICaseStudyFilterOptionProps[];
  selectedOptions?: ServiceIcons[];
  setSelectedOptions?: (selectedOptions: ServiceIcons[]) => void;
}

const CaseStudyFilter: FC<ICaseStudyFilterProps> = ({
  wrapperClassName = [styles.CaseStudyFilter, pageStyles.ContentAlign].join(
    " "
  ),
  headerText = "Find a Case Study by Discipline",
  headerClassName = [pageStyles.h1].join(" "),
  headerStyle = {},
  filterLabelText = "Select",
  filterLabelClassName = [pageStyles.h2].join(" "),
  filterLabelStyle = {},
  filterOptions = [],
  selectedOptions = [],
  setSelectedOptions = () => {},
}: ICaseStudyFilterProps) => {
  const [_isOpen, _setIsOpen] = useState(false);
  const onFilterBtnClick = () => {
    const onTransitioned = (e: TransitionEvent) => {
      if (overlayRef.current) {
        if (
          e &&
          e.propertyName === "max-height" &&
          overlayRef.current.style.maxHeight === "0"
        ) {
          console.log("animation done");
          overlayRef.current.style.display = "none";
        }
        overlayRef.current.removeEventListener("transitionend", onTransitioned);
      }
    };

    if (overlayRef.current) {
      overlayRef.current.addEventListener("transitionend", onTransitioned);
    }

    _setIsOpen((isOpen) => {
      if (overlayRef.current && !isOpen)
        overlayRef.current.style.display = "flex";
      return !isOpen;
    });
  };
  const overlayRef = useRef<HTMLDivElement>(null);
  const getOnFilterOptionClick = (filterOptionId: ServiceIcons) => {
    return () => {
      let result = [...selectedOptions];
      if (selectedOptions.length === filterOptions.length) {
        result = [filterOptionId];
      } else if (
        selectedOptions.length === 1 &&
        filterOptionId === selectedOptions[0]
      ) {
        // do nothing last item.
      } else {
        if (selectedOptions.indexOf(filterOptionId) === -1) {
          result = [...selectedOptions, filterOptionId];
        } else {
          result = result.filter((serviceKey) => serviceKey !== filterOptionId);
        }
      }
      setSelectedOptions(result);
    };
  };
  const optionsElement = (
    <div
      ref={overlayRef}
      className={[
        styles.OptionsOverlay,
        _isOpen ? styles.OptionsOverlayOpen : styles.OptionsOverlayClosed,
      ].join(" ")}
    >
      {filterOptions.map((filterOption, index) => {
        return (
          <CaseStudyFilterOption
            key={index}
            {...filterOption}
            isSelected={selectedOptions.indexOf(filterOption.id) !== -1}
            onChange={
              _isOpen ? getOnFilterOptionClick(filterOption.id) : () => {}
            }
            style={{ cursor: _isOpen ? "pointer" : "default" }}
          />
        );
      })}
    </div>
  );
  return (
    <div className={wrapperClassName}>
      <div
        className={headerClassName}
        dangerouslySetInnerHTML={{ __html: headerText }}
        style={headerStyle}
      />
      <div className={styles.FilterButton} onClick={onFilterBtnClick}>
        <div
          className={filterLabelClassName}
          dangerouslySetInnerHTML={{ __html: filterLabelText }}
          style={filterLabelStyle}
        />
        <Arrow
          className={[
            styles.Arrow,
            _isOpen ? styles.ArrowOpen : styles.ArrowClosed,
          ].join(" ")}
        />
      </div>
      {optionsElement}
    </div>
  );
};

export default CaseStudyFilter;
