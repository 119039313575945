// This file will eventually become the config.json

import { IDynamicCaseStudyPageConfig } from "../../DynamicConfigTypes";
import DropConfig from "./DropConfig";
import DivaCupDynConfig from "./DivaCupConfig";
import HunterDouglas from "./HunterDouglas";
import BiosheerConfig from "./BiosheerConfig";
import FawConfig from "./FawConfig";
import VytaConfig from "./VytaConfig";

// Home data
const CaseStudiesConfig: IDynamicCaseStudyPageConfig[] = [
  VytaConfig,
  FawConfig,
  DropConfig,
  BiosheerConfig,
  DivaCupDynConfig,
  HunterDouglas,
];

export default CaseStudiesConfig;
