import Routes from "../../../components/App/Routes";
import {
  CoreDynamicComponents,
  CSDynamicComponents,
  IDynamicCaseStudyPageConfig,
  IImageData,
  PageDynamicComponents,
} from "../../DynamicConfigTypes";
import { ServiceIcons } from "../../models/Services";
import { ICaseStudyHeroData } from "../../../components/CaseStudy/CaseStudyHero";
import { IImageHeroData } from "../../../components/CaseStudy/ImageHero";

import hero_mobi_src from "../../../assets/case_study/faw/hero_mobi.png";
import hero_src from "../../../assets/case_study/faw/hero.png";
import hero_logo_src from "../../../assets/case_study/faw/carousel_logo.png";
import p_skinny_src from "../../../assets/case_study/faw/p_hero1.png";
import p_img_src from "../../../assets/case_study/faw/p_hero2.png";
import p_img_src_2 from "../../../assets/case_study/faw/p_hero3.png";
import p_img_src_3 from "../../../assets/case_study/faw/p_hero4.png";
import p_img_src_4 from "../../../assets/case_study/faw/p_hero5.png";
import p_img_src_5 from "../../../assets/case_study/faw/p_hero6.png";
import full_src from "../../../assets/case_study/faw/content_full.png";
import full_src_2 from "../../../assets/case_study/faw/content_full2.png";
import full_src_3 from "../../../assets/case_study/faw/content_full3.png";
import tile_src from "../../../assets/case_study/faw/tile.png";

import styles from "../../../pages/CaseStudy.module.css";
import pageStyles from "../../../components/Page/Page.module.css";
import { ICaseStudyTileData } from "../../../components/CaseStudy/TileList/CaseStudyTile";
import { ITextWithHeaderData } from "../../../components/Page/TextWithHeader";

const cs_title = "Foreign Affair Winery";
const cs_route = Routes.Faw;
/**
 * The Hero reference.<br />
 * Used in the:<br />
 * * Home Case Studies Carousel<br />
 * * Works Case Studies Heroes List<br />
 * * Case Study Pages themselves<br />
 */
const cs_hero: ICaseStudyHeroData = {
  img: hero_src,
  mobi_img: hero_mobi_src,
  title: cs_title,
  description: `
                Facilitating discovery and sales of The Foreign Affair Winery, enabling Canadian wine lovers ways to engage, experience and shop the brand. <a href='${Routes.Faw}'>Read More</a>
            `,
  services: [
    ServiceIcons.SocialMedia,
    ServiceIcons.Advertising,
    ServiceIcons.MediaPlanning,
    ServiceIcons.ECommerce,
  ],
  logo: hero_logo_src,
  link: cs_route,
};

const cs_tile: ICaseStudyTileData = {
  img: tile_src,
  title: cs_title,
  subtitle:
    "Letting Canada in on a secret: How one Ontario winery raises its grapes to speak Italian.",
  link: cs_route,
};

/**
 * The Case Study Config used across the App.
 */
const FawConfig: IDynamicCaseStudyPageConfig = {
  title: cs_title,
  slug: "faw", //TODO
  route: cs_route,
  isInHomeCarousel: false, //TODO
  hero: cs_hero,
  tile: cs_tile,
  data: [
    {
      type: PageDynamicComponents.WordMarkLink,
      data: {
        className: [pageStyles.CenterAlign, pageStyles.HomeLink].join(" "),
      },
    },
    {
      type: CSDynamicComponents.CaseStudyHero,
      data: {
        ...cs_hero,
        style: { marginTop: "30px" },
        titleClass: [pageStyles.h1].join(" "),
        descriptionClass: [pageStyles.p2].join(" "),
        featuredClass: [pageStyles.p2].join(" "),
        doesTitleLink: false,
        showReadMore: false,
      } as ICaseStudyHeroData,
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        className: [
          pageStyles.ThirdFourthAlign,
          styles.SectionVerticalSpace,
          pageStyles.AfterHeroFix, //TODO
        ].join(" "),
        headerContent: "Background",
        headerContentClass: [pageStyles.h1].join(" "),
        textContent: `
        <p>Canadian wine drinkers love discovering local wineries to share and impress their friends with their knowledge of local wine.  That's especially true when that winery is attached to an interesting back-story. The Foreign Affair Winery had a rich and interesting origin story, but it was a story that wasn't coming to life in a way that communicated the brand's personality or encouraged discovery of the winery or wines. </p>
        <br />
        <p>The winery itself served as a source of discovery, where visitors were educated about the brand and the vintages.  But with the winery closed to visitors (due to COVID-19) we looked to digital channels to tell the brand story, differentiate FAW from the same old, same old category advertising and drive retail and direct to consumer sales.</p>
    `,
        textContentClass: [pageStyles.p2].join(" "),
      } as ITextWithHeaderData,
    },
    {
      type: CSDynamicComponents.ImageHeroSlim,
      data: {
        src: p_skinny_src,
        headerContent: `
                Wine has the ability to elevate an everyday moment to a special occasion, shared with friends & family or offer an opportunity to enjoy  a moment of quiet reflection.
            `,
      } as IImageHeroData,
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        className: [
          pageStyles.ThirdFourthAlign,
          styles.SectionVerticalSpace,
        ].join(" "),
        headerContent: "Solution",
        headerContentClass: [pageStyles.h1].join(" "),
        textContent: `
          <p>Taking inspiration from the bottle art, juxtaposing a classical Italian landscape with Canadian animals, we looked to insert these icons of Canadiana into moments and everyday occasions a bit closer to home for the Canadian wine drinker. </p>
          <br />
          <p>By leaning into the brand identity and personality, we looked to engage, entertain and educate Canadian wine drinkers, while differentiating The Foreign Affair Winery from the same-old, same-old wine category advertising.</p>
          <br />
          <p>Relying solely on social and search media, we created a highly targeted campaign against Ontario and Quebec wine drinkers, driving them to retail partners and the brand site for purchase and sign-up for The Foreign Affair Winery Wine Club.</p>
      `,
        textContentClass: pageStyles.p2,
        textWrapperClass: styles.ImageHeroTextWrapper,
      },
    },
    {
      type: CSDynamicComponents.ImageHeroRight,
      data: {
        src: p_img_src,
        headerContent: `
                Facilitating brand<br />discovery
            `,
        textContent: `
                <p>We built awareness of the brand amongst consumers actively conducting online searches for wine in Ontario, driving traffic to the winery's website, where they could learn about and shop direct.</p>
                <br />
                <p>Collaborating with our in-house media partner Hatch64, we developed branded and category keyword campaigns, delivering against website traffic and sales conversion goals. </p>
            `,
      } as IImageHeroData,
    },
    {
      type: CSDynamicComponents.ImageHeroLeft,
      data: {
        src: p_img_src_2,
        textContent: `
            <p>Taking inspiration from the bottle art, featuring silhouettes of Canadian wildlife against an Italian fresco landscape, we brought these icons of Canadiana to life, inserting them into everyday occasions a little more familiar to Canadian wine drinkers.</p>
            <br />
            <p>Taking a non-traditional approach to category advertising, social video ads brought the bottle art to life to help improve brand recognition and recall when consumers were visiting their local LCBO or SAQ.</p>
            <br/>
            <p>Making the most of policy changes at Facebook & Instagram, we revisited our creative approach to ensure that every ad was not only tied to the occasion, but also helped educate consumers on the distinctive flavour profile of the featured wines. </p>
            `,
        headerContent: `
                Increasing brand<br />recognition & recall
            `,
      },
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_src,
        alt: "",
      } as IImageData,
    },
    {
      type: CSDynamicComponents.ImageHeroRight,
      data: {
        src: p_img_src_3,
        textContent: `
                <p>We knew just telling wine lovers how great wine from The Foreign Affair Winery is would only go so far.  We also needed to get wine lovers to share their experiences and help convince other wine lovers to make The Foreign Affair Winery their next ”must try” bottle.</p>
                <br />
                <p>We launched a branded page on Vivino, allowing people who are passionate about wine to review The Foreign Affair Winery vintages and varietals.  And they were happy to offer their opinions, which the reviews are in and they are nothing short of outstanding across the board.</p>
            `,
        headerContent: `
                Allowing wine lovers to<br />share their experience
            `,
      },
    },
    {
      type: CSDynamicComponents.ImageHeroLeft,
      data: {
        src: p_img_src_4,
        textContent: `
                <p>Deepening wine drinkers engagement with the winery meant getting them to experience more offerings from the winery more regularly.  We retargeted past visitors to the Foreign Affair Winery website, educating them of the convenience and member-exclusive benefits of joining the Foreign Affair Wine Club and encouraging them to sign-up.</p>
                <br />
                <p>The Foreign Affair Wine Club was positioned as being as distinctive as its members.  All Wine Club ads featured wine lovers sporting the iconic Foreign Affair antlers, again reinforcing brand equities and breaking the mold of traditional winery advertising.</p>
                `,
        headerContent: `
                    Driving subscriptions<br />to the Wine Club 
                `,
      },
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_src_2,
        alt: "",
      } as IImageData,
    },
    {
      type: CSDynamicComponents.ImageHeroRight,
      data: {
        src: p_img_src_5,
        headerContent: `
                    Getting into the<br />holiday spirit 
                `,
        textContent: `
                    <p>To support sales during the peak holiday season, paid and owned media  featured the Foreign Affair animals sporting festive red scarves.</p>
                    <br />
                    <p>Custom QR codes offered consumers holiday recipes that paired with the featured vintage, providing some inspiration to make the holidays just a little brighter.<p>
                `,
      },
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_src_3,
        alt: "",
      } as IImageData,
    },
    {
      type: CoreDynamicComponents.Button,
      data: {
        label: "See All Projects",
        link: Routes.Work,
        className: [pageStyles.CenterAlign, styles.SectionVerticalSpace].join(
          " "
        ),
      },
    },
    {
      type: PageDynamicComponents.PageDivider,
    },
    {
      type: PageDynamicComponents.PageFooter,
    },
  ],
};

export default FawConfig;
