import { ScrollRestoration } from "react-router-dom";
import pageStyles from "../components/Page/Page.module.css";
import styles from "./Privacy.module.css";
import HomeLink from "../components/Page/HomeLink";
const Privacy = () => {
  return (
    <div className={[pageStyles.Page, styles.Privacy].join(" ")}>
      <ScrollRestoration />
      <HomeLink className={styles.Logo} />
      <div className={pageStyles.ContentAlign}>
        <h1>Privacy Policy</h1>
        <h2>Context</h2>
        <p>
          Smith+Mason co. Inc. and its subsidiaries (referred to herein as
          “SMCO” or "we" or "us") values our customers and respects your
          privacy.
        </p>
        <p>
          This Privacy Policy (“Policy”) applies to our offline and online data
          collection practices, including when you visit our website and any
          website owned, operated, or controlled by us (collectively the
          “Site”), contact us by phone or email, or when you engage with us on
          social media.
        </p>
        <p>
          Our sites contain links to other Web sites over which we have no
          control. Smith+Mason co. is not responsible for the privacy policies
          or practices of other Web sites to which you choose to link from a
          Smith+Mason co. Web site. We encourage you to review the privacy
          policies of those other Web sites so you can understand how they
          collect, use and share your information. This Privacy Policy applies
          solely to the information we collect on a Smith+Mason co.&nbsp; Web
          site, and does not apply to information we collect in any other
          fashion.
        </p>
        <p>
          Smith+Mason co. complies with laws of Canada including the Personal
          Information Protection and Electronic Documents Act.
        </p>
        <p>
          We may update this Policy from time to time, as specified in the
          “Changes to This Privacy Policy” section below.
        </p>
        <h3>Your Consent</h3>
        <p>
          You should read this entire Policy before submitting information to us
          or using our Site. Whenever you submit personal information to us, you
          consent to the collection, use, disclosure, transfer and storage of
          that information in accordance with this Policy.
        </p>
        <p>
          We may make full use of all information that is de-identified,
          aggregated, or otherwise not in personally identifiable form.
        </p>
        <h3>Information Collection and Use</h3>
        <p>
          Information Collection and Use We collect personal information using
          three methods:
        </p>
        <p>1) from you;</p>
        <p>
          2) using automated technology including when you visit our Site or
          communicate with us through email; and
        </p>
        <p>3) through third party sources including our service providers.</p>
        <p>
          All personal information will be used as stated in this Policy. We
          will retain your personal information for the period necessary to
          fulfill the purposes outlined in this Policy and as otherwise needed
          to comply with applicable law and internal company policies.
        </p>
        <p>
          We may combine data collected from these disparate sources unless we
          tell you otherwise.
        </p>
        <h3>Retention of Data</h3>
        <p>
          We will retain your personal information (collected through offline
          and online methods) for as long as it is necessary for the purposes
          described in this Policy. We will also retain and use your personal
          information to the extent necessary to comply with our legal
          obligations, resolve disputes, and enforce our legal agreements and
          policies.
        </p>
        <h3>Intended Audience of Site</h3>
        <p>
          Our Site, social media accounts and online activities are not directed
          to children under the age of 16. As a result, our Site does not
          request or knowingly collect personal data from individuals under the
          age of 16. If you are not 17 or older, you should not visit or use our
          Site.
        </p>
        <h3>Security</h3>
        <p>
          We take reasonable apprbopriate steps to protect personal data from
          loss, misuse and unauthorized access, disclosure, alteration or
          destruction, whether in transmission or storage. Please keep in mind,
          however, that there is no such thing as perfect security, and no
          Internet transmission is ever completely secure or error-free
        </p>
        <p>
          We offer the use of a secure server. All supplied sensitive/credit
          information is transmitted via Secure Socket Layer (SSL) technology
          hosted by a third party and then encrypted into our payment gateway
          provider’s database only to be accessible by those authorized with
          special access rights to such systems, and who are required to keep
          the information confidential.
        </p>
        <h3>Cookies and other automated technology</h3>
        <p>
          A “cookie” is a small text file that is placed onto an Internet user’s
          web browser or device and is used to remember and/or obtain
          information about the user. A “web beacon” is a small object or image
          that is embedded into a web page, application, or email and is used to
          track activity. They are also sometimes referred to as pixels and
          tags.
        </p>
        <h3>Analytics Disclaimer</h3>
        <p>
          We and/or third parties including our service providers on our behalf,
          may use cookies, web beacons and other similar technology, to collect
          information for the purposes described in this privacy statement
          including analytics, and monitoring performance and improvement of our
          Site (traffic, errors, page load time, popular pages, etc.)
        </p>
        <h3>Contact for More Information</h3>
        <p>
          For information and questions about the use of your personal
          information or this Policy, you may contact us at
          privacy@smithandmason.co
        </p>
        <h3>Changes to This Privacy Policy</h3>
        <p>
          This Policy may be revised from time to time for any reason. If this
          Policy changes, the revised policy will include a new effective date,
          and we will notify you of such changes by posting the revised policy
          on this page. Be sure to check the Policy whenever you submit personal
          information or use our Site, services, or engage with our social media
          accounts.
        </p>
      </div>
    </div>
  );
};
export default Privacy;
