import Routes from "../../components/App/Routes";
import {
  CSDynamicComponents,
  IDynamicPageConfig,
  PageDynamicComponents,
  ServicesDynamicComponents,
} from "../DynamicConfigTypes";
import pageStyles from "../../components/Page/Page.module.css";
import { ServicesProvided } from "../models/Services";

const ServicesConfig: IDynamicPageConfig = {
  title: "Services",
  slug: "services",
  route: Routes.Services,
  data: [
    {
      type: PageDynamicComponents.WordMarkLink,
      data: {
        className: [pageStyles.CenterAlign, pageStyles.HomeLink].join(" "),
      },
    },
    {
      type: PageDynamicComponents.PageHeader,
      data: {
        content: "Our Services",
      },
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        className: [pageStyles.ContentAlign, pageStyles.SectionSpace].join(" "),
        headerContent: "Our commitment to creative problem solving",
        textContentClass: [pageStyles.p2].join(" "),
        textContent: `
            We are multidisciplinary at our core and
            combine best practice thinking across
            channels to arrive at non-traditional,
            innovative solutions to old problems.
            From developing visual brand systems
            and narratives to creating engaging
            advertising and online content that
            keeps audiences engaged, our team
            creates compelling communication that
            connects people with brands and the
            world around them.
        `,
        headerContentClass: [pageStyles.h1].join(" "),
      },
    },
    {
      type: ServicesDynamicComponents.ServiceList,
      data: {
        headerContentClass: [pageStyles.h2].join(" "),
        textContentClass: [pageStyles.p2].join(" "),
        className: [pageStyles.ContentAlign, pageStyles.SectionSpace].join(" "),
        servicesList: Object.values(ServicesProvided).map(
          (serviceProviced, index) => ({
            textContent: serviceProviced.description,
            headerContent: serviceProviced.title,
            icon: serviceProviced.icon,
            isLargeIcon: index % 2 !== 0,
            isLeftOriented: index % 2 === 0,
          })
        ),
      },
    },
    { type: CSDynamicComponents.FilteredTileList },
    {
      type: PageDynamicComponents.PageDivider,
    },
    { type: PageDynamicComponents.PageFooter },
  ],
};

export default ServicesConfig;
