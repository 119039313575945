import React, {
  useState,
  Children,
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  CSSProperties,
} from "react";
import { ReactComponent as ArrowPrev } from "../../assets/CarouselArrowPrev.svg";
import { ReactComponent as ArrowNext } from "../../assets/CarouselArrowNext.svg";
import { ReactComponent as ItemDot } from "../../assets/CarouselItem.svg";
import styles from "./CarouselBasic.module.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./carousel.overwrite.css";

export interface ICarouselBasicProps {
  className?: string;
  style?: CSSProperties;
  children: ReactElement[];
  isHunterDouglas?: boolean;
}

const CarouselBasic: FunctionComponent<
  PropsWithChildren<ICarouselBasicProps>
> = (props) => {
  const [_currentSlide, _setCurrentSlide] = useState(0);
  const next = () => {
    _setCurrentSlide((lastCurrentSlide) => lastCurrentSlide + 1);
  };
  const prev = () => {
    _setCurrentSlide((lastCurrentSlide) => lastCurrentSlide - 1);
  };
  const updateCurrentSlide = (index: any) => {
    _setCurrentSlide(index);
  };
  const nextArrow = (
    <ArrowNext
      className={[styles.Arrow, styles.ArrowNext].join(" ")}
      onClick={next}
      fill={props.isHunterDouglas ? "#000000" : "var(--s_m_font_color"}
    />
  );
  const prevArrow = (
    <ArrowPrev
      className={[styles.Arrow, styles.ArrowPrev].join(" ")}
      onClick={prev}
      fill={props.isHunterDouglas ? "#000000" : "var(--s_m_font_color"}
    />
  );

  const indicators = Children.map(props.children, (child, index) => {
    return (
      <ItemDot
        key={index}
        className={styles.CarouselItemIndicator}
        onClick={() => {
          updateCurrentSlide(index);
        }}
        fill={
          props.isHunterDouglas === true
            ? index === _currentSlide
              ? "#000000"
              : "#757572"
            : index === _currentSlide
            ? "var(--s_m_font_color"
            : "#757572"
        }
      />
    );
  });

  return (
    <div className={props.className} style={props.style}>
      <Carousel
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        showIndicators={false}
        infiniteLoop={true}
        selectedItem={_currentSlide}
        autoPlay={false}
        interval={10000}
        onChange={updateCurrentSlide}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        {...props}
      ></Carousel>
      <div className={styles.CarouselControls}>
        {prevArrow}
        {indicators}
        {nextArrow}
      </div>
    </div>
  );
};

export default CarouselBasic;
