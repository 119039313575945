import { FC } from "react";
import { Link } from "react-router-dom";
import { IBaseComponentData } from "../../config/DynamicConfigTypes";
import pageStyles from "../Page/Page.module.css";
import { ReactComponent as Logo } from "../../assets/Logo.svg";
import { ReactComponent as WordMark } from "../../assets/S_M_WORDMARK.svg";

export interface IHomeLinkData extends IBaseComponentData {
  link?: string;
}
export interface IHomeLinkProps extends IHomeLinkData {
  isWordMark?: boolean;
}

const HomeLink: FC<IHomeLinkProps> = ({
  className = "",
  link = "/",
  isWordMark = false,
}) => {
  return (
    <Link to={link} className={className}>
      {isWordMark ? (
        <WordMark className={[pageStyles.LogoWordMark].join(" ")} />
      ) : (
        <Logo className={[pageStyles.LogoBunny].join(" ")} />
      )}
    </Link>
  );
};

export default HomeLink;
