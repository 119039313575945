import { FC } from "react";
import styles from "./SocialIcons.module.css";
import { ReactComponent as Facebook } from "../../assets/Facebook.svg";
import { ReactComponent as LinkedIn } from "../../assets/LinkedIn.svg";
export interface ISocialIconsProps {
  className?: string;
}
const SocialIcons: FC<ISocialIconsProps> = (props) => {
  return (
    <div className={[styles.SocialIcons, props.className].join(" ")}>
      <a
        href="https://www.facebook.com/people/SmithMason-co/100088596545084/"
        target="_blank"
      >
        <Facebook className={[styles.Facebook, "theme-hover"].join(" ")} />
      </a>
      <a
        href="https://www.linkedin.com/company/smith-and-mason-co/"
        target="_blank"
      >
        <LinkedIn className={[styles.Instagram, "theme-hover"].join(" ")} />
      </a>
    </div>
  );
};

export default SocialIcons;
