import { Link } from "react-router-dom";
import { ReactComponent as WordMarkLogo } from "../../assets/S+M WORDMARK.svg";
import { ReactComponent as MenuBtn } from "../../assets/MenuBtn.svg";
import { ReactComponent as CloseMenuBtn } from "../../assets/CloseMenuBtn.svg";
import { ReactComponent as MenuBG } from "../../assets/Menu_Frame.svg";
import styles from "./AppMenu.module.css";
import Routes from "./Routes";
import AppContext from "./AppContext";
import { useContext } from "react";
import pageStyles from "../Page/Page.module.css";
import { ThemeToggle } from "./ThemeToggle";
import SocialIcons from "../Page/SocialIcons";

type AppMenuProps = {};

const AppMenu: React.FC<AppMenuProps> = (props) => {
  const { isDarkMode, setIsDarkMode, isMenuOpen, setIsMenuOpen } =
    useContext(AppContext);
  const onMenuBtnClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const onThemeBtnClick = (checked: boolean) => {
    setIsDarkMode(checked);
  };
  const onLinkClick = () => {
    setIsMenuOpen(false);
  };

  const links = (
    <div className={styles.Drawer}>
      <MenuBG className={styles.HalfCircle} />
      <div className={[styles.Links].join(" ")}>
        <header>
          <Link
            to={Routes.Home}
            onClick={onLinkClick}
            className={styles.LogoClosed}
          >
            <WordMarkLogo />
          </Link>
        </header>
        <Link to="about" onClick={onLinkClick} className={pageStyles.Jumbo}>
          About Us
        </Link>
        <Link to="work" onClick={onLinkClick} className={pageStyles.Jumbo}>
          Our Work
        </Link>
        <Link to="services" onClick={onLinkClick} className={pageStyles.Jumbo}>
          Services
        </Link>
        <SocialIcons className={styles.SocialIcons} />
      </div>
    </div>
  );
  return (
    <nav id="menu" className={styles.Menu}>
      <div
        className={[
          styles.Header,
          isMenuOpen ? styles.Open : styles.Closed,
        ].join(" ")}
      >
        {links}
        <ThemeToggle
          checked={isDarkMode}
          onChange={onThemeBtnClick}
          size={35}
          className={styles.ThemeToggle}
        />
        {isMenuOpen ? (
          <CloseMenuBtn onClick={onMenuBtnClick} className={styles.CloseBtn} />
        ) : (
          <MenuBtn className={styles.MenuBtn} onClick={onMenuBtnClick} />
        )}
      </div>
    </nav>
  );
};

export default AppMenu;
