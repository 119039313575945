import * as React from "react";
import { IBaseComponentData } from "../../../config/DynamicConfigTypes";
import TextWithHeader, { ITextWithHeaderData } from "../../Page/TextWithHeader";
import AboutHeroBase from "./AboutHeroBase";

export interface IAboutHeroData extends IBaseComponentData {
  leftHero: ITextWithHeaderData;
  rightHero: ITextWithHeaderData;
}

const AboutHero: React.FC<IAboutHeroData> = (props) => {
  const leftHero = <TextWithHeader {...props.leftHero} />;
  const rightHero = <TextWithHeader {...props.rightHero} />;
  return (
    <AboutHeroBase
      leftElement={leftHero}
      rightElement={rightHero}
      className={props.className}
      style={props.style}
    />
  );
};
export default AboutHero;
