import { createBrowserRouter } from "react-router-dom";
import AboutConfig from "../../config/pages/AboutConfig";
import BiosheerConfig from "../../config/pages/caseStudies/BiosheerConfig";
import DivaCupDynConfig from "../../config/pages/caseStudies/DivaCupConfig";
import HunterDouglasConfig from "../../config/pages/caseStudies/HunterDouglas";
import DropConfig from "../../config/pages/caseStudies/DropConfig";
import FawConfig from "../../config/pages/caseStudies/FawConfig";
import VytaConfig from "../../config/pages/caseStudies/VytaConfig";
import HomeConf from "../../config/pages/HomeConf";
import ServicesConfig from "../../config/pages/ServicesConfig";
import WorkConfig from "../../config/pages/WorkConfig";
import Privacy from "../../pages/Privacy";
import Page from "../Page/Page";
import AppError from "./AppError";
import AppUI from "./AppUI";
import Routes from "./Routes";

const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <AppUI />,
    errorElement: <AppError />,
    children: [
      {
        path: Routes.Home,
        index: true,
        element: <Page config={HomeConf} />,
      },
      {
        path: Routes.About,
        element: <Page config={AboutConfig} />,
      },
      {
        path: Routes.Services,
        element: <Page config={ServicesConfig} />,
      },
      {
        path: Routes.Work,
        element: <Page config={WorkConfig} />,
      },
      {
        path: Routes.Biosheer,
        element: <Page config={BiosheerConfig} />,
      },
      {
        path: Routes.Vyta,
        element: <Page config={VytaConfig} />,
      },
      {
        path: Routes.HunterDouglas,
        element: <Page config={HunterDouglasConfig} />,
      },
      {
        path: Routes.Faw,
        element: <Page config={FawConfig} />,
      },
      {
        path: Routes.Drop,
        element: <Page config={DropConfig} />,
      },
      {
        path: Routes.DivaCup,
        element: <Page config={DivaCupDynConfig} />,
      },
      {
        path: Routes.Hunter,
        element: <Page config={HunterDouglasConfig} />,
      },
      {
        path: Routes.Privacy,
        element: <Privacy />,
      },
    ],
  },
]);

export default AppRouter;
