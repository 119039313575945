import { ReactComponent as AdvertisingIcon } from "../../assets/services_icons/Advertising.svg";
import { ReactComponent as ContentMarketingIcon } from "../../assets/services_icons/ContentMarketing.svg";
import { ReactComponent as CRMIcon } from "../../assets/services_icons/CRM.svg";
import { ReactComponent as DesignBrandingIcon } from "../../assets/services_icons/DesignBranding.svg";
import { ReactComponent as DigitalSolutionsIcon } from "../../assets/services_icons/DigitalSolutions.svg";
import { ReactComponent as ECommerceIcon } from "../../assets/services_icons/ECommerce.svg";
import { ReactComponent as MediaPlanningIcon } from "../../assets/services_icons/MediaPlanning.svg";
import { ReactComponent as SocialMediaIcon } from "../../assets/services_icons/SocialMedia.svg";
import { ReactComponent as WebsiteDevIcon } from "../../assets/services_icons/WebsiteDev.svg";
import { ServiceIcons } from "../../config/models/Services";

type ServiceIconProps = {
  className?: string;
  ico?: ServiceIcons;
  onClick?: () => void;
};
const defaultProps: ServiceIconProps = {
  className: "",
  ico: ServiceIcons.Advertising,
};
const ServiceIcon: React.FC<ServiceIconProps> = (props) => {
  let icon = <AdvertisingIcon />;
  switch (props.ico) {
    // this is to test out mikes shitty environment
    case ServiceIcons.Advertising:
      icon = <AdvertisingIcon />;
      break;
    case ServiceIcons.ContentMarketing:
      icon = <ContentMarketingIcon />;
      break;
    case ServiceIcons.CRM:
      icon = <CRMIcon />;
      break;
    case ServiceIcons.DesignBranding:
      icon = <DesignBrandingIcon />;
      break;
    case ServiceIcons.DigitalSolutions:
      icon = <DigitalSolutionsIcon />;
      break;
    case ServiceIcons.ECommerce:
      icon = <ECommerceIcon />;
      break;
    case ServiceIcons.MediaPlanning:
      icon = <MediaPlanningIcon />;
      break;
    case ServiceIcons.SocialMedia:
      icon = <SocialMediaIcon />;
      break;
    case ServiceIcons.WebsiteDev:
      icon = <WebsiteDevIcon />;
      break;
  }
  return (
    <div className={props.className} onClick={props.onClick}>
      {icon}
    </div>
  );
};
ServiceIcon.defaultProps = defaultProps;
export default ServiceIcon;
