import Routes from "../../../components/App/Routes";
import {
  CoreDynamicComponents,
  CSDynamicComponents,
  IDynamicCaseStudyPageConfig,
  IImageData,
  PageDynamicComponents,
} from "../../DynamicConfigTypes";
import { ServiceIcons } from "../../models/Services";
import { ICaseStudyHeroData } from "../../../components/CaseStudy/CaseStudyHero";
import { IImageHeroData } from "../../../components/CaseStudy/ImageHero";

import hero_mobi_src from "../../../assets/case_study/hunter/p_hero1_mb.png";
import hero_src from "../../../assets/case_study/hunter/p_hero1_desktop.png";
import hero_logo_src from "../../../assets/case_study/hunter/p_logo.png";
import p_skinny_src from "../../../assets/case_study/hunter/p_column1.png";
import p_img_src from "../../../assets/case_study/hunter/p_column4.png";
import p_img_src_2 from "../../../assets/case_study/hunter/p_column2.png";
import p_img_src_3 from "../../../assets/case_study/hunter/p_column3.png";
import p_img_src_4 from "../../../assets/case_study/hunter/p_column5.png";
import full_src from "../../../assets/case_study/hunter/p_fullscreen1.png";
import full_src_2 from "../../../assets/case_study/hunter/p_fullscreen2.png";
import tile_src from "../../../assets/case_study/hunter/tile.png";
import full_width_bg from "../../../assets/case_study/hunter/hd_logo.png";

import styles from "../../../pages/CaseStudy.module.css";
import pageStyles from "../../../components/Page/Page.module.css";
import { ICaseStudyTileData } from "../../../components/CaseStudy/TileList/CaseStudyTile";
import { ITextWithHeaderData } from "../../../components/Page/TextWithHeader";
import { IColumnTextWithHeaderData } from "../../../components/Page/ColumnTextWithHeader";
import { IVideoCarouselData } from "../../../components/Carousel/VideoCarousel";

const cs_title = "Hunter Douglas";
const cs_route = Routes.HunterDouglas;
/**
 * The Hero reference.<br />
 * Used in the:<br />
 * * Home Case Studies Carousel<br />
 * * Works Case Studies Heroes List<br />
 * * Case Study Pages themselves<br />
 */
const cs_hero: ICaseStudyHeroData = {
  img: hero_src,
  mobi_img: hero_mobi_src,
  title: cs_title,
  description: `
  Transforming the way Hunter Douglas was connecting with customers, assisting in a return to business as usual, in a time that was anything but.
`,
  services: [
    ServiceIcons.DesignBranding,
    ServiceIcons.SocialMedia,
    ServiceIcons.Advertising,
    ServiceIcons.MediaPlanning,
    ServiceIcons.ECommerce,
  ],
  logo: hero_logo_src,
  link: cs_route,
};

const cs_tile: ICaseStudyTileData = {
  img: tile_src,
  title: cs_title,
  subtitle:
    "Transforming the way Hunter Douglas was connecting with customers, assisting in a return to business as usual, in a time that was anything but.",
  link: cs_route,
};

/**
 * The Case Study Config used across the App.
 */
const VytaDynConfig: IDynamicCaseStudyPageConfig = {
  title: cs_title,
  slug: "faw", //TODO
  route: cs_route,
  isInHomeCarousel: true, //TODO
  hero: cs_hero,
  tile: cs_tile,
  data: [
    {
      type: PageDynamicComponents.WordMarkLink,
      data: {
        className: [pageStyles.CenterAlign, pageStyles.HomeLink].join(" "),
      },
    },
    {
      type: CSDynamicComponents.CaseStudyHero,
      data: {
        ...cs_hero,
        style: { marginTop: "30px" },
        titleClass: [pageStyles.h1].join(" "),
        descriptionClass: [pageStyles.p2].join(" "),
        featuredClass: [pageStyles.p2].join(" "),
      } as ICaseStudyHeroData,
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        className: [
          pageStyles.ThirdFourthAlign,
          styles.SectionVerticalSpace,
          pageStyles.AfterHeroFix, //TODO
        ].join(" "),
        headerContent: "Background",
        headerContentClass: [pageStyles.h1].join(" "),
        textContent: `
        <p>Hunter Douglas design specialists have been invited into countless Canadian homes to help customers select the right window treatments to fit their needs and design aesthetic. The in-home consultation is an important step in helping designers understand a customer's space, and how different people use different rooms of their home at different times of the day.</p>
        <br />
        <p>But with the rise of COVID-19, customers were no longer comfortable with the idea of opening up their homes. And with Design Studios being closed to the public, like many companies, Hunter Douglas was experiencing an unprecedented interruption to their business operation.  So, we looked to digital solutions to continue to service customers while supporting the Hunter Douglas dealer network.</p>

        `,
        textContentClass: [pageStyles.p2].join(" "),
      } as ITextWithHeaderData,
    },
    {
      type: CSDynamicComponents.ImageHeroSlim,
      data: {
        src: p_skinny_src,
        headerContent: `
          An interruption to business as usual, created opportunities for Hunter Douglas to connect with customers in new ways, keeping dealers open for business, virtually everywhere. 
        `,
      } as IImageHeroData,
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        className: [
          pageStyles.ThirdFourthAlign,
          styles.SectionVerticalSpace,
        ].join(" "),
        headerContent: "Solution",
        headerContentClass: [pageStyles.h1].join(" "),
        textContent: `
            <p>We developed an integrated campaign, “Open, Virtually Everywhere” to signal to the public that Hunter Douglas was still open for business.  With customer and employee safety being a top concern, we let consumers know they could still take on their home design project with confidence, offering one-on-one virtual design consultations with Hunter Douglas design specialists. Hunter Douglas Design Specialists were provided training on how to deliver effective virtual consultations, while consumers were given step-by-step instruction on how to prepare and get the most out of their time with their design specialist. </p>
            <br />
            <p>A national search and social media campaign targeted consumers with an expressed interest in home design and window coverings.  Radio and the storefronts of Hunter Douglas Design Studios were used to build awareness at a local level, getting dealers actively involved in the success of the campaign.</p>
            <br />
            <p>Visitors to the website could get more information and book their Virtual Consultation, and familiarize themselves with Hunter Douglas’ full range of designer fabrics and systems.  A series of videos focused on wellness in the home were included as part of the campaign to provide design inspiration and help customers make smart choices for their home and their families.</p>
        `,
        textContentClass: pageStyles.p2,
        textWrapperClass: styles.ImageHeroTextWrapper,
      } as ITextWithHeaderData,
    },
    {
      type: CSDynamicComponents.ImageHeroRight,
      data: {
        src: p_img_src,
        imgClass: pageStyles.ScaledImage,
        headerContent: `
                Keeping retail <br />open for business
            `,
        textContent: `
                <p>Instead of letting storefronts sit empty and dark, we used the store fronts of Hunter Douglas showrooms to let visitors and passersby know that while physical locations were closed, Hunter Douglas was still open for business to support their design needs – virtually everywhere.</p>
                <br />
                <p>Signage let customers know they could still get expert advice from Hunter Douglas Design Specialists and browse all the latest fabrics and systems, all from the safety and comfort of home with a personal one-on-one virtual consultation.</p>
            `,
      } as IImageHeroData,
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_src,
        alt: "",
      } as IImageData,
    },
    {
      type: CSDynamicComponents.ImageHeroRight,
      data: {
        src: p_img_src_2,
        imgClass: [pageStyles.ScaledImage, pageStyles.tenPercent].join(" "),
        headerContent: `
              Letting people know<br /> what to expect
            `,
        textContent: `
                <p>Working with our media partner Hatch64, we developed a paid social campaign.  Video ads explained what consumers could expect when they signed-up for a Virtual Consultation and how to prepare to get the most out of their time with their Hunter Douglas Design Specialist.</p>
                <br />
                <p>Facebook and Instagram ads were created to inform people of the key steps they could take to inform their design project through a virtual consultation, letting them know there was no need to put off their home design project.</p>
            `,
      } as IImageHeroData,
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_src_2,
        alt: "",
      } as IImageData,
    },
    {
      type: CSDynamicComponents.ImageHeroRight,
      data: {
        src: p_img_src_3,
        imgClass: pageStyles.ScaledImage,
        headerContent: `
                Getting the message<br /> out on the airwaves
            `,
        textContent: `
                <p>To support Hunter Douglas dealers, a series of :30 and :15 second radio spots were created and broadcast on localized radio networks, encouraging audiences to visit the Hunter Douglas website to learn more about booking their one-on-one virtual consultation.</p>
                <br />
                <p>Dealers were also provided the pre-recorded spots and had the ability to customize the radio commercials to support their businesses, by allowing them to have local announcers tag the spots with their own website and store information.</p>
            `,
      } as IImageHeroData,
    },
    {
      type: CSDynamicComponents.ImageHeroLeft,
      data: {
        src: p_img_src_4,
        headerContent: `
                Helping people book<br /> their Consultation
                `,
        textContent: `
                    <p>Visitors to the website were provided with all the information they needed to understand the Virtual Design consultation process and what they could do in advance of their consultation to get the most out of the experience, including getting familiar with Hunter Douglas’ full range of designer fabrics and systems.</p>
                    <br />
                    <p>Once ready, the site allowed them to book their Virtual Design Consultation directly on the site for a time that was convenient for them, and putting them in touch directly with their Hunter Douglas Design Specialist.<p>
                `,
      },
    },
    {
      type: PageDynamicComponents.ColumnTextWithHeader,
      data: {
        className: [pageStyles.ContentAlign, styles.SectionVerticalSpace].join(
          " "
        ),
        headerContent:
          "Providing customers design<br /> inspiration with their wellness in<br /> mind",
        headerContentClass: [pageStyles.h1].join(" "),
        leftTextContent: `
            <p>We know that people put a lot of pressure on themselves to make the right design decision when selecting window coverings for their homes.  It’s an investment in the home and typically a decision people live with everyday for many years.</p>
            <br />
            <p>Our surroundings play an important role in how we feel and with people spending more time living and working at home, we developed a series of online videos to provide website visitors, not only design inspiration, but considerations for designing a healthier home.</p>
        `,
        rightTextContent: `
            <p>But, why take our word for it?  Instead we brought in the experts to get their opinions on the role design and light play in our overall well-being and long-term health.  The video series featured conversations with  a Naturopathic Doctor, a Clinical Sleep Counsellor and a Board Member of Canada’s Colour Research Society.</p>
            <br />
            <p>Each episode focused on a single aspect of design and it’s importance to our well-being and what to consider when selecting lighting and shading products for the home.</p>
        `,
        textContentClass: pageStyles.p2,
      } as IColumnTextWithHeaderData,
    },
    {
      type: PageDynamicComponents.VideoCarousel,
      data: {
        backgroundImage: full_width_bg,
        imgAlt: "Background Image",
        videos: [
          {
            video: "https://youtu.be/GH4dtl0Ohyc",
            mobi_video: "https://youtu.be/GH4dtl0Ohyc",
          },
          {
            video: "https://youtu.be/CpFTxav5i_8",
            mobi_video: "https://youtu.be/CpFTxav5i_8",
          },
          {
            video: "https://youtu.be/x1iGQS_PUI4",
            mobi_video: "https://youtu.be/x1iGQS_PUI4",
          },
          {
            video: "https://youtu.be/78ne2ICUUTM",
            mobi_video: "https://youtu.be/78ne2ICUUTM",
          },
          {
            video: "https://youtu.be/R_wOEiyU9Eo",
            mobi_video: "https://youtu.be/R_wOEiyU9Eo",
          },
        ],
      } as IVideoCarouselData,
    },
    {
      type: CoreDynamicComponents.Button,
      data: {
        label: "See All Projects",
        link: Routes.Work,
        className: [pageStyles.CenterAlign, styles.SectionVerticalSpace].join(
          " "
        ),
      },
    },
    {
      type: PageDynamicComponents.PageDivider,
    },
    {
      type: PageDynamicComponents.PageFooter,
    },
  ],
};

export default VytaDynConfig;
