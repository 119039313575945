import { CSSProperties } from "react";
import { Parallax } from "react-scroll-parallax";
import { IBaseComponentData } from "../../config/DynamicConfigTypes";
import { useCaptureLinkClicksFromHTML } from "../../hooks/useCaptureLinkClicks";
import styles from "./ColumnTextWithHeader.module.css";

export interface IColumnTextWithHeaderData extends IBaseComponentData {
  headerContent?: string;
  headerContentClass?: string;
  headerContentStyle?: CSSProperties;
  leftTextContent: string;
  textContentClass?: string;
  textContentStyle?: CSSProperties;
  rightTextContent: string;
}
export interface IColumnTextWithHeaderProps extends IColumnTextWithHeaderData {}

const ColumnTextWithHeader: React.FC<IColumnTextWithHeaderProps> = ({
  className = "",
  headerContent = "",
  leftTextContent = "",
  rightTextContent = "",
  headerContentClass = "",
  textContentClass = "",
  style = {},
  textContentStyle = {},
  headerContentStyle = {},
}) => {
  const captureLinkClicks = useCaptureLinkClicksFromHTML();
  return (
    <Parallax className={`${styles.TextWithHeader} ${className}`} style={style}>
      <header
        className={headerContentClass}
        dangerouslySetInnerHTML={{ __html: headerContent }}
        onClick={captureLinkClicks}
        style={headerContentStyle}
      />
      <div className={styles.textWrapperContentClass}>
        <div
          className={textContentClass}
          dangerouslySetInnerHTML={{ __html: leftTextContent }}
          style={textContentStyle}
          onClick={captureLinkClicks}
        />
        <div
          className={textContentClass}
          dangerouslySetInnerHTML={{ __html: rightTextContent }}
          style={textContentStyle}
          onClick={captureLinkClicks}
        />
      </div>
    </Parallax>
  );
};

export default ColumnTextWithHeader;
