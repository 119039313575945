import styles from "./ServiceListItem.module.css";
import ServiceIcon from "../ServiceIcon";
import { IBaseComponentData } from "../../../config/DynamicConfigTypes";
import { useNavigate } from "react-router";
import { ServiceIcons } from "../../../config/models/Services";
import { CSSProperties } from "react";

export interface IServiceListItemData extends IBaseComponentData {
  headerContent?: string;
  headerContentClass?: string;
  headerContentStyle?: CSSProperties;
  textContent?: string;
  textContentClass?: string;
  textContentStyle?: CSSProperties;
  isLeftOriented: boolean;
  isLargeIcon: boolean;
  icon: ServiceIcons;
  iconClickLink?: string;
}
export interface IServiceListItemProps extends IServiceListItemData {
  iconClick?: () => void;
}

const ServiceListItem = (props: IServiceListItemProps) => {
  const navigate = useNavigate();
  const clickNavigate = () => {
    if (props.iconClickLink) {
      navigate("/" + props.iconClickLink);
    }
  };
  const onClick = props.iconClick || clickNavigate;
  const getIcon = (isLarge: Boolean, classes: Array<String>) => {
    if (isLarge) {
      return (
        <ServiceIcon
          ico={props.icon}
          onClick={onClick}
          className={[styles.itemIconLarge, classes.join(" ")].join(" ")}
        />
      );
    } else {
      return (
        <ServiceIcon
          ico={props.icon}
          onClick={onClick}
          className={[styles.itemIconNormal, classes.join(" ")].join(" ")}
        />
      );
    }
  };

  const serviceItem = (isLeftOriented: Boolean, isLarge: Boolean) => {
    if (isLeftOriented) {
      return (
        <div className={styles.ServiceListItemContainerLeft}>
          <div className={styles.itemIconContainer}>
            {getIcon(isLarge, [styles.itemIconLeft])}
          </div>
          <div className={styles.infoContainer}>
            <div
              className={[styles.title, props.headerContentClass].join(" ")}
              style={props.headerContentStyle}
            >
              {props.headerContent}
            </div>
            <div
              className={[styles.description, props.textContentClass].join(" ")}
              style={props.textContentStyle}
            >
              {props.textContent}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.ServiceListItemContainerRight}>
          <div className={styles.itemIconContainer}>
            {getIcon(isLarge, [styles.itemIconRight])}
          </div>
          <div className={styles.infoContainer}>
            <div
              className={[styles.title, props.headerContentClass].join(" ")}
              style={props.headerContentStyle}
            >
              {props.headerContent}
            </div>
            <div
              className={[styles.description, props.textContentClass].join(" ")}
              style={props.textContentStyle}
            >
              {props.textContent}
            </div>
          </div>
        </div>
      );
    }
  };

  return serviceItem(props.isLeftOriented, props.isLargeIcon);
};

export default ServiceListItem;
