import Routes from "../../../components/App/Routes";
import {
  CoreDynamicComponents,
  CSDynamicComponents,
  IDynamicCaseStudyPageConfig,
  IImageData,
  PageDynamicComponents,
} from "../../DynamicConfigTypes";
import { ServiceIcons } from "../../models/Services";
import { ICaseStudyHeroData } from "../../../components/CaseStudy/CaseStudyHero";
import { IImageHeroData } from "../../../components/CaseStudy/ImageHero";

import hero_mobi_src from "../../../assets/case_study/vyta/hero_mobi.png";
import hero_src from "../../../assets/case_study/vyta/hero.png";
import hero_logo_src from "../../../assets/case_study/vyta/carousel_logo.png";
import p_skinny_src from "../../../assets/case_study/vyta/p_hero1.png";
import p_img_src from "../../../assets/case_study/vyta/p_hero2.png";
import p_img_src_2 from "../../../assets/case_study/vyta/p_hero3.png";
import p_img_src_3 from "../../../assets/case_study/vyta/p_hero4.png";
import p_img_src_4 from "../../../assets/case_study/vyta/p_hero5.png";
import full_src from "../../../assets/case_study/vyta/content_full.png";
import full_src_2 from "../../../assets/case_study/vyta/content_full2.png";
import full_src_3 from "../../../assets/case_study/vyta/content_full3.png";
import tile_src from "../../../assets/case_study/vyta/tile.png";

import styles from "../../../pages/CaseStudy.module.css";
import pageStyles from "../../../components/Page/Page.module.css";
import { ICaseStudyTileData } from "../../../components/CaseStudy/TileList/CaseStudyTile";
import { ITextWithHeaderData } from "../../../components/Page/TextWithHeader";
import { IImageHeroPopUpData } from "../../../components/CaseStudy/ImageHeroPopUp/ImageHeroPopUp";

const cs_title = "Vyta";
const cs_route = Routes.Vyta;
/**
 * The Hero reference.<br />
 * Used in the:<br />
 * * Home Case Studies Carousel<br />
 * * Works Case Studies Heroes List<br />
 * * Case Study Pages themselves<br />
 */
const cs_hero: ICaseStudyHeroData = {
  img: hero_src,
  mobi_img: hero_mobi_src,
  title: cs_title,
  description: `
  Giving seniors a viable alternative to institutional care by allowing them to age in place safely, securely and healthily, in the homes and communities they love. <a href='${Routes.Vyta}'>Read More</a>
`,
  services: [
    ServiceIcons.DesignBranding,
    ServiceIcons.SocialMedia,
    ServiceIcons.Advertising,
    ServiceIcons.ECommerce,
    ServiceIcons.MediaPlanning,
  ],
  logo: hero_logo_src,
  link: cs_route,
};

const cs_tile: ICaseStudyTileData = {
  img: tile_src,
  title: cs_title,
  subtitle:
    "Letting Canada in on a secret: How one Ontario winery raises its grapes to speak Italian.",
  link: cs_route,
};

/**
 * The Case Study Config used across the App.
 */
const VytaConfig: IDynamicCaseStudyPageConfig = {
  title: cs_title,
  slug: "faw", //TODO
  route: cs_route,
  isInHomeCarousel: true, //TODO
  hero: cs_hero,
  tile: cs_tile,
  data: [
    {
      type: PageDynamicComponents.WordMarkLink,
      data: {
        className: [pageStyles.CenterAlign, pageStyles.HomeLink].join(" "),
      },
    },
    {
      type: CSDynamicComponents.CaseStudyHero,
      data: {
        ...cs_hero,
        style: { marginTop: "30px" },
        titleClass: [pageStyles.h1].join(" "),
        descriptionClass: [pageStyles.p2].join(" "),
        featuredClass: [pageStyles.p2].join(" "),
        showReadMore: false,
        doesTitleLink: false,
      } as ICaseStudyHeroData,
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        className: [
          pageStyles.ThirdFourthAlign,
          styles.SectionVerticalSpace,
          pageStyles.AfterHeroFix, //TODO
        ].join(" "),
        headerContent: "Background",
        headerContentClass: [pageStyles.h1].join(" "),
        textContent: `
        The COVID-19 pandemic exposed deficiencies in Canada's institutional care facilities and the standard of care afforded to residents of those facilities.  It also called into question the healthcare system's preparedness to respond to the demands that will be placed upon it as the baby boom generation continues to become more aged. 

        Given the choice, many seniors would prefer to age in their own home and community but find it increasingly difficult to keep up with the demands of household maintenance as they age.  Often, it is their families who end up taking on their loved one's care, leaving their children spending more time helping with household chores than focusing on the things that matter most – spending quality time with their loved ones, creating more moments and memories.  
        `,
        textContentClass: [pageStyles.p2].join(" "),
      } as ITextWithHeaderData,
    },
    {
      type: CSDynamicComponents.ImageHeroSlim,
      data: {
        src: p_skinny_src,
        headerContent: `
        Vyta was created to help seniors live and age at home in their community, with all the care, respect and dignity a life well-lived deserves.
        `,
      } as IImageHeroData,
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        className: [
          pageStyles.ThirdFourthAlign,
          styles.SectionVerticalSpace,
        ].join(" "),
        headerContent: "Solution",
        headerContentClass: [pageStyles.h1].join(" "),
        textContent: `
            <p>Partnering with Bayshore HealthCare, we developed a new brand, aimed at giving seniors a viable alternative to institutional care, allowing them to age in place in the homes and communities they love, safely, securely and healthily. </p>
            <br />
            <p>We called it “Vyta”: A celebration of life, providing Canadian seniors with the help they need to continue focusing on living their best lives at home and making the most of their time with friends and family doing the things they love most.</p>
            <br />
            <p>Offering seniors and their families customized service packages through a network of trusted local home and well-being service providers, Vyta provides concierge services, managing all home services with the convenience of one consolidated monthly bill. So, seniors can ”enjoy life, and let Vyta take care of the rest.” </p>
            <br />
            <p>An integrated campaign supported the introduction of the brand, encouraging the audience to visit the website to learn more about Vyta's services and to contact a Vyta representative to build a custom service bundle tailored to the individual senior's needs. </p>
        `,
        textContentClass: pageStyles.p2,
        textWrapperClass: styles.ImageHeroTextWrapper,
      },
    },
    {
      type: CSDynamicComponents.ImageHeroRight,
      data: {
        src: p_img_src,
        headerContent: `
                Giving life to a<br />brand-new brand
            `,
        textContent: `
                <p>We developed the brand from the ground up, from naming and positioning to developing a visual design language (inclusive of logo design, custom iconography, photography, etc.) that steered clear of the trappings of cliché retirement home and senior care advertising.</p>
                <br />
                <p>Instead, we developed a fresh and modern aesthetic, that celebrated active seniors, engaged with friends, family and their community. </p>
                <br />
                <p>Our rules were simple: communicate vitality, energy and possibility, reflecting the active nature of ”living in place” vs. ”aging in place” in everything we do.  </p>
            `,
      } as IImageHeroData,
    },
    {
      type: CSDynamicComponents.ImageHeroLeft,
      data: {
        src: p_img_src_2,
        textContent: `
                <p>The Vyta website needed to be place where visitors felt welcomed and could get the information they needed to make a decision about their or their loved one’s care easily.  It was important to offer an experience that was both informative but also simple to allow seniors easy access to the information they were looking for. </p>
                <p>UX design took into consideration the senior target audience and potential usability issues they may have.  Site design included large fonts to help with legibility and large format buttons to allow for ease of navigation (even on mobile). </p>
            `,
        headerContent: `
                Building a home for<br />Vyta to live
            `,
      },
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_src,
        alt: "",
      } as IImageData,
    },
    {
      type: CSDynamicComponents.ImageHeroRight,
      data: {
        src: p_img_src_3,
        textContent: `
                <p>Research told us the families of seniors are often involved in the decisions for their care.  So, we ensured we weren’t just telling seniors about Vyta, but targeted their adult children with ads on lifestyle sites, social networks and search.</p>
                <br />
                <p>We put the time-saving benefits of Vyta into context for the adult children of seniors (who often take on the role of caregiver as their parents age) allowing them to spend more quality time with their loved one and less time helping them with household chores.</p>
            `,
        headerContent: `
                Putting Vyta’s benefits<br />into context
            `,
      },
    },
    {
      type: CSDynamicComponents.ImageHeroPopUpLeft,
      data: {
        popupLabel: "See Inside",
        popupClass: [pageStyles.p2].join(" "),
        popupSrc: full_src_2,
        src: p_img_src_4,
        headerContent: `
                    Getting into the<br />holiday spirit 
                `,
        textContent: `
                    <p>To support sales during the peak holiday season, paid and owned media  featured the Foreign Affair animals sporting festive red scarves.</p>
                    <br />
                    <p>Custom QR codes offered consumers holiday recipes that paired with the featured vintage, providing some inspiration to make the holidays just a little brighter.<p>
                `,
      } as IImageHeroPopUpData,
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_src_3,
        alt: "",
      } as IImageData,
    },
    {
      type: CoreDynamicComponents.Button,
      data: {
        label: "See All Projects",
        link: Routes.Work,
        className: [pageStyles.CenterAlign, styles.SectionVerticalSpace].join(
          " "
        ),
      },
    },
    {
      type: PageDynamicComponents.PageDivider,
    },
    {
      type: PageDynamicComponents.PageFooter,
    },
  ],
};

export default VytaConfig;
