export enum Badges {
  AuxEnergy,
  BayShore,
  HomeRun,
  HunterDouglas,
  LordJones,
  Mawer,
  TheDrop,
  UJA,
  Vyta,
  Winery,
}
