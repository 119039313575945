import {
  CoreDynamicComponents,
  HomeDynamicComponents,
  IDynamicPageConfig,
  PageDynamicComponents,
  ServicesDynamicComponents,
} from "../DynamicConfigTypes";
import pageStyles from "../../components/Page/Page.module.css";
import styles from "../../pages/Home.module.css";
import Routes from "../../components/App/Routes";
import DropConfig from "./caseStudies/DropConfig";
import { Badges } from "../models/Badges";
import FawConfig from "./caseStudies/FawConfig";
import VytaConfig from "./caseStudies/VytaConfig";
import { ServicesProvided } from "../models/Services";

const HomeConf: IDynamicPageConfig = {
  title: "Home",
  slug: "",
  route: Routes.Home,
  data: [
    {
      type: PageDynamicComponents.BunnyLink,
      data: {
        className: [styles.Logo].join(" "),
      },
    },
    {
      type: HomeDynamicComponents.HeroHeader,
      data: {
        className: [styles.HeroHeader].join(" "),
      },
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        className: [pageStyles.ContentAlign, styles.TextContentBlock].join(" "),
        headerContent:
          "Helping brands build a better tomorrow through purposeful design + technology",
        headerContentClass: [pageStyles.h1, styles.TextSectionHeader].join(" "),
        headerContentStyle: {},
        textContent: `
            We are a strategic marketing and creative
            communications agency. We are committed to 
            the belief that ideas have the ability to change
            the world for the better and we are dedicated 
            to the craft of giving those ideas form.
        `,
        textContentClass: [pageStyles.p2, styles.TextSectionContent].join(" "),
      },
    },
    {
      type: CoreDynamicComponents.Button,
      data: {
        label: "About us",
        link: Routes.About,
        className: [pageStyles.ContentAlign, styles.AboutUsBtn].join(" "),
      },
    },
    {
      type: HomeDynamicComponents.CaseStudyCarousel,
      data: {
        caseStudies: [
          {
            title: DropConfig.hero.title,
            text: DropConfig.hero.description,
            img: DropConfig.hero.img,
            mobi_img: DropConfig.hero.mobi_img,
            link: DropConfig.hero.link,
            logo: DropConfig.hero.logo,
            showLogo: true,
          },
          {
            title: FawConfig.hero.title,
            text: FawConfig.hero.description,
            img: FawConfig.hero.img,
            mobi_img: FawConfig.hero.mobi_img,
            link: FawConfig.hero.link,
            logo: FawConfig.hero.logo,
            showLogo: true,
          },
          {
            title: VytaConfig.hero.title,
            text: VytaConfig.hero.description,
            img: VytaConfig.hero.img,
            mobi_img: VytaConfig.hero.mobi_img,
            link: VytaConfig.hero.link,
            logo: VytaConfig.hero.logo,
            showLogo: true,
          },
        ],
        textClass: pageStyles.p2,
        headerClass: pageStyles.h1,
        featuredClass: pageStyles.p3,
        featuredLabel: "",
      },
    },
    {
      type: CoreDynamicComponents.Button,
      data: {
        label: "See All Work",
        link: Routes.Work,
        className: [pageStyles.CenterAlign, styles.OurServicesBtn].join(" "),
      },
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        className: [pageStyles.ContentAlign, styles.TextContentBlock].join(" "),
        headerContent: `We are a collection of artists, thinkers, creators and builders`,
        headerContentClass: [pageStyles.h1, styles.TextSectionHeader].join(" "),
        textContent: `We are multidisciplinary at our
            core, combining the art of
            storytelling and discipline of
            design with technology to create
            work with purpose. Because we
            believe what we do should serve a
            greater purpose in the world and
            for the people who live in it.
        `,
        textContentClass: [pageStyles.p2, styles.TextSectionContent].join(" "),
      },
    },
    {
      type: CoreDynamicComponents.Container,
      data: {
        className: [pageStyles.ContentAlign, styles.ServiceHeroWrapper].join(
          " "
        ),
        children: [
          {
            type: ServicesDynamicComponents.ServiceList,
            data: {
              iconClickLink: Routes.Services,
              headerContentClass: [pageStyles.h2].join(" "),
              textContentClass: [pageStyles.p2].join(" "),
              servicesList: [
                {
                  isLargeIcon: false,
                  isLeftOriented: true,
                  headerContent: ServicesProvided.WebsiteDev.title,
                  textContent: ServicesProvided.WebsiteDev.description,
                  icon: ServicesProvided.WebsiteDev.icon,
                },
                {
                  isLargeIcon: false,
                  isLeftOriented: true,
                  headerContent: ServicesProvided.CRM.title,
                  textContent: ServicesProvided.CRM.description,
                  icon: ServicesProvided.CRM.icon,
                },
                {
                  isLargeIcon: false,
                  isLeftOriented: true,
                  headerContent: ServicesProvided.Advertising.title,
                  textContent: ServicesProvided.Advertising.description,
                  icon: ServicesProvided.Advertising.icon,
                },
              ],
            },
          },
          {
            type: CoreDynamicComponents.Button,
            data: {
              label: "Our Services",
              link: Routes.Services,
              className: [pageStyles.CenterAlign, styles.OurServicesBtn].join(
                " "
              ),
            },
          },
        ],
      },
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        className: [pageStyles.ContentAlign, styles.TextContentBlock].join(" "),
        headerContent: `We are client partners,
            collaborators and guides`,
        headerContentClass: [pageStyles.h1, styles.TextSectionHeader].join(" "),
        textContent: `
                We advocate on behalf of our clients, helping them navigate the ever-changing \n communications landscape to build and transform their businesses and brands. 
                We partner with clients as an extension of their internal team, working with \n purpose to develop work with purpose. 
                `,
        textContentClass: [pageStyles.p2, styles.TextSectionContent].join(" "),
      },
    },
    {
      type: HomeDynamicComponents.BadgesHero,
      data: {
        className: [pageStyles.ContentAlign, styles.Badges].join(" "),
        badges: [
          Badges.Mawer,
          Badges.Vyta,
          Badges.BayShore,
          Badges.LordJones,
          Badges.HomeRun,
          Badges.HunterDouglas,
          Badges.AuxEnergy,
          Badges.UJA,
          Badges.Winery,
          Badges.TheDrop,
        ],
      },
    },
    {
      type: PageDynamicComponents.PageDivider,
    },
    {
      type: PageDynamicComponents.PageFooter,
    },
  ],
};
export default HomeConf;
