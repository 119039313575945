import { RouterProvider } from 'react-router';
import { ParallaxProvider } from 'react-scroll-parallax';
import AppContextProvider from './AppContextProvider';
import AppRouter from './AppRouter';

function App() {
  return (
    <ParallaxProvider>
      <RouterProvider router={AppRouter} />
    </ParallaxProvider>
  );
}

export default App;
