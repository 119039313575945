import { FunctionComponent, useContext } from "react";
import ImageWithOverlay from "../Page/ImageWithOverlay";
import styles from "./CaseStudyHero.module.css";
import pageStyles from "../Page/Page.module.css";
import ServiceIcon from "../Services/ServiceIcon";
import { useCaptureLinkClicksFromHTML } from "../../hooks/useCaptureLinkClicks";
import AppContext from "../App/AppContext";
import { Link } from "react-router-dom";
import { ServiceIcons } from "../../config/models/Services";
import { IBaseComponentData } from "../../config/DynamicConfigTypes";

export interface ICaseStudyHeroData extends IBaseComponentData {
  img: string;
  imgClickable?: boolean;
  mobi_img: string;
  description: string;
  services: ServiceIcons[];
  title: string;
  showTitle?: boolean;
  doesTitleLink?: boolean;
  titleClass?: string;
  logo: string;
  showLogo?: boolean;
  link: string;
  descriptionClass?: string;
  showReadMore?: boolean;
  featuredClass?: string;
}
export interface ICaseStudyHeroProps extends ICaseStudyHeroData {}

const CaseStudyHero: FunctionComponent<ICaseStudyHeroProps> = ({
  className,
  style,
  img,
  mobi_img,
  imgClickable,
  title,
  titleClass = pageStyles.h1,
  showTitle = true,
  doesTitleLink = true,
  description,
  descriptionClass = pageStyles.p2,
  services,
  logo,
  showLogo = true,
  link,
  showReadMore = true,
  featuredClass = pageStyles.p2,
}) => {
  const captureLinkClicks = useCaptureLinkClicksFromHTML();
  const { isMobile } = useContext(AppContext);
  const serviceIcons = services.map((serviceIco, index) => (
    <ServiceIcon key={index} className={styles.ServiceIcon} ico={serviceIco} />
  ));
  const descriptionClasses = [
    styles.Description,
    pageStyles.ContentAlign,
    showReadMore ? "" : styles.HideReadMore,
    descriptionClass,
  ].join(" ");
  const titleClasses = [pageStyles.ContentAlign, titleClass].join(" ");
  const titleElement = (
    <p
      className={titleClasses}
      dangerouslySetInnerHTML={{ __html: title }}
      onClick={captureLinkClicks}
      style={{ display: showTitle ? "block" : "none" }}
    ></p>
  );
  const titleLink = doesTitleLink ? (
    <Link to={`/${link}`} className={pageStyles.NoUnderline}>
      {titleElement}
    </Link>
  ) : (
    titleElement
  );

  const imgEl = <ImageWithOverlay src={isMobile ? mobi_img : img} />;
  const imgLink = imgClickable ? <Link to={`/${link}`}>{imgEl}</Link> : imgEl;

  return (
    <div className={[styles.CaseStudyHero, className].join(" ")} style={style}>
      {imgLink}
      {showLogo ? (
        <img className={styles.CarouselLogo} src={logo} alt="" />
      ) : null}
      <section>
        <div className={[featuredClass, pageStyles.ContentAlign].join(" ")}>
          Case Study
        </div>
        {titleLink}
        <p
          className={descriptionClasses}
          dangerouslySetInnerHTML={{ __html: description }}
          onClick={captureLinkClicks}
        ></p>
        <div
          className={[styles.ServiceIcoWrapper, pageStyles.ContentAlign].join(
            " "
          )}
        >
          {serviceIcons}
        </div>
      </section>
    </div>
  );
};

export default CaseStudyHero;
