import { FC, useEffect, useState } from "react";
import { IBaseComponentData } from "../../../config/DynamicConfigTypes";
import {
  getCaseStudiesForServices,
  getTilesFromCaseStudies,
} from "../caseStudy.utils";
import CaseStudyFilter from "../CaseStudyFilter";
import CaseStudyTileList from "../TileList/CaseStudyTileList";

import pageStyles from "../../Page/Page.module.css";
import styles from "./FilteredTileList.module.css";
import {
  IServiceProvided,
  ServiceIcons,
  ServicesProvided,
} from "../../../config/models/Services";
import { ICaseStudyFilterOptionProps } from "../CaseStudyFilterOption";

export interface IFilteredTileListData extends IBaseComponentData {}

const FilteredTileList: FC<IFilteredTileListData> = ({ className, style }) => {
  const filterOptions: ICaseStudyFilterOptionProps[] = Object.keys(
    ServicesProvided
  ).map((serviceKey) => {
    const service: IServiceProvided =
      ServicesProvided[serviceKey as ServiceIcons];

    return {
      id: serviceKey as ServiceIcons,
      icon: service.icon,
      label: service.title,
      isSelected: true,
    };
  });

  const [_selectedOptions, _setSelectedOptions] = useState(
    filterOptions.map((filterOption) => filterOption.id)
  );

  const setSelectedOptions = (selectedOptions: ServiceIcons[]) => {
    _setSelectedOptions(selectedOptions);
  };

  const [_caseStudyTileData, _setCaseStudyTileData] = useState(
    getCaseStudiesForServices().map((caseStudy, index) => index)
  );

  // Change the list of tiles when the selected options change
  useEffect(() => {
    _setCaseStudyTileData(
      getCaseStudiesForServices(_selectedOptions).map(
        (caseStudy, index) => index
      )
    );
  }, [_selectedOptions]);

  return (
    <div className={styles.wrapper}>
      <CaseStudyFilter
        filterOptions={filterOptions}
        selectedOptions={_selectedOptions}
        setSelectedOptions={setSelectedOptions}
      />
      <CaseStudyTileList
        data={getTilesFromCaseStudies()}
        tilesVisible={_caseStudyTileData}
        className={[pageStyles.ContentAlign].join(" ")}
      />
    </div>
  );
};

export default FilteredTileList;
