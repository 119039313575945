import { CSSProperties, FC } from "react";
import ServiceIcon from "../Services/ServiceIcon";
import pageStyles from "../Page/Page.module.css";
import styles from "./CaseStudyFilterOption.module.css";
import { ReactComponent as CheckMarkOutline } from "../../assets/Checkmark_Outline.svg";
import { ServiceIcons } from "../../config/models/Services";

export interface ICaseStudyFilterOptionProps {
  id: ServiceIcons;
  icon: ServiceIcons | null;
  label: string;
  labelClassName?: string;
  onChange?: () => void;
  isSelected: boolean;
  style?: CSSProperties;
}

export const CaseStudyFilterOption: FC<ICaseStudyFilterOptionProps> = ({
  id = ServiceIcons.Advertising,
  icon = null,
  label = "Test",
  labelClassName = [pageStyles.p2].join(" "),
  onChange = () => {
    console.log("Changed!");
  },
  isSelected = true,
  style,
}) => {
  const styleProps: CSSProperties = { ...style, opacity: isSelected ? 1 : 0.4 };
  return (
    <div className={styles.Wrapper} style={styleProps} onClick={onChange}>
      {icon === null ? (
        <div className={styles.Icon} />
      ) : (
        <ServiceIcon ico={icon} className={styles.Icon} />
      )}
      <div
        className={labelClassName}
        dangerouslySetInnerHTML={{ __html: label }}
      ></div>
      <CheckMarkOutline className={styles.CheckMark} />
    </div>
  );
};
