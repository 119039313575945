import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import AppContext from "./AppContext";
import styles from "../Page/Page.module.css";

const AppContextProvider: FunctionComponent<PropsWithChildren> = (props) => {
  const isDarkKey = "isDark";
  const isDark =
    window.localStorage.getItem(isDarkKey) === null
      ? true
      : window.localStorage.getItem(isDarkKey) === "true";
  const [_isDarkMode, _setIsDarkMode] = useState(isDark);
  const [_isMenuOpen, _setIsMenuOpen] = useState(false);
  const [_isMobile, _setIsMobile] = useState(false);
  const setIsDarkMode = (isDark: boolean) => {
    _setIsDarkMode(isDark);
    setBodyClasses();
    window.localStorage.setItem(isDarkKey, isDark.toString());
  };
  const setIsMenuOpen = (isOpen: boolean) => {
    _setIsMenuOpen(isOpen);
    setBodyClasses();
  };
  const setBodyClasses = () => {
    if (_isDarkMode) {
      document.body.classList.add("dark");
      document.body.classList.remove("light");
    } else {
      document.body.classList.remove("dark");
      document.body.classList.add("light");
    }
    if (_isMenuOpen) {
      document.body.classList.add(styles.NoScroll);
      document.body.classList.remove(styles.Scroll);
    } else {
      document.body.classList.remove(styles.NoScroll);
      document.body.classList.add(styles.Scroll);
    }
  };
  setBodyClasses();

  useEffect(() => {
    _setIsMobile(window.innerWidth < 1025);
    const onResize = () => {
      console.log();
      _setIsMobile(window.innerWidth < 1025);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <AppContext.Provider
      value={{
        isDarkMode: _isDarkMode,
        setIsDarkMode: setIsDarkMode,
        isMenuOpen: _isMenuOpen,
        setIsMenuOpen: setIsMenuOpen,
        isMobile: _isMobile,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
