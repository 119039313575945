import { FunctionComponent } from 'react';
import styles from './ImageWithOverlay.module.css';

type ImageWithOverlayProps = {
  src: string;
  alt?: string;
  imageClassName?: string;
  onClick?: () => void;
};

const ImageWithOverlay: FunctionComponent<ImageWithOverlayProps> = props => {
  return (
    <div className={styles.ImageWithOverlay} onClick={props.onClick}>
      <img
        src={props.src}
        alt={props.alt || ''}
        className={props.imageClassName}
      />
      <div className={styles.ImageOverlay} />
    </div>
  );
};

export default ImageWithOverlay;
