import { useNavigate } from "react-router";
import { IBaseComponentData } from "../../config/DynamicConfigTypes";
import styles from "./Btn.module.css";

export interface IButtonData extends IBaseComponentData {
  label: string;
  link?: string;
}
export interface IBtnProps extends IButtonData {
  onClick?: () => void;
}
const Btn: React.FC<IBtnProps> = ({
  label = "Button",
  style = {},
  className = "",
  onClick = undefined,
  link = undefined,
}) => {
  const navigate = useNavigate();
  const onBtnClick = () => {
    if (onClick) {
      onClick();
    }
    if (link) {
      navigate("/" + link);
    }
  };
  return (
    <button
      onClick={onBtnClick}
      className={[styles.Btn, className].join(" ")}
      style={style}
    >
      {label}
    </button>
  );
};
export default Btn;
