import {
  AboutDynamicComponents,
  CoreDynamicComponents,
  IDynamicPageConfig,
  PageDynamicComponents,
  ServicesDynamicComponents,
} from "../DynamicConfigTypes";
import pageStyles from "../../components/Page/Page.module.css";
import styles from "../../pages/About.module.css";
import Routes from "../../components/App/Routes";
import { ServicesProvided } from "../models/Services";
import { IGrid4Data } from "../../components/About/Grid4/Grid4";

const AboutConfig: IDynamicPageConfig = {
  title: "About us",
  slug: "about",
  route: Routes.About,
  data: [
    {
      type: PageDynamicComponents.WordMarkLink,
      data: {
        className: [pageStyles.CenterAlign, pageStyles.HomeLink].join(" "),
      },
    },
    {
      type: PageDynamicComponents.PageHeader,
      data: {
        content: "About us",
      },
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        headerContent: `Allow us to introduce ourselves`,
        className: [
          pageStyles.ContentAlign,
          styles.AboutContent,
          pageStyles.SectionSpace,
        ].join(" "),
        headerContentClass: pageStyles.h1,
        textContentClass: pageStyles.p1,
        textContent: `Smith+Mason is a full-service communications agency, designed with the singular purpose of putting value back into the agency equation, helping our clients transform their businesses and build brands for a better tomorrow.
            <br />
            <br />
            We are partners and guides, helping our clients navigate the ever-changing communications landscape. We are allies and advisors, identifying lanes of opportunity. We are advocates for progress and change. You could say, we’re an agency that acts with agency.`,
      },
    },
    {
      type: AboutDynamicComponents.AboutHero,
      data: {
        className: [
          pageStyles.SectionSpace,
          pageStyles.ContentAlign,
          styles.AboutHero,
        ].join(" "),
        leftHero: {
          className: [styles.LeftHeroSection, styles.HeroSection].join(" "),
          headerContent: `We are Smiths`,
          headerContentClass: pageStyles.h1,
          subHeaderContent: `We are artists, wordsmiths, strategists and storytellers.`,
          textContent: `We are artisans and craftspeople, dedicated to the creative craft and the discipline of marketing science, because we believe ideas can change the world.`,
          textContentClass: pageStyles.p2,
          subHeaderClass: pageStyles.p1,
        },
        rightHero: {
          headerContent: `We are Masons`,
          subHeaderContent: `We are builders, architects, technicians, and engineers.`,
          textContent: `We are technologists and tradespeople, dedicated to designing platforms and foundations on which business is built and audiences engage.`,
          className: [styles.RightHeroSection, styles.HeroSection].join(" "),
          headerContentClass: pageStyles.h1,
          textContentClass: pageStyles.p2,
          subHeaderClass: pageStyles.p1,
        },
      },
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        headerContent: `We Work with Purpose`,
        subHeaderContent: `Change is only made possible when some one first believes that change is needed and has an idea of what that change should be`,
        textContent: `We are a collection of artists, thinkers, creators and builders, dedicated to the belief that ideas can change how we communicate with each other and bring about positive change.  It’s giving those ideas shape, form and purpose that compels us. 
        We believe the work we do should have a greater purpose in the world to help our clients and their customers build a better tomorrow.  It’s the founding principle of our agency and a mantra we take seriously.`,
        className: [pageStyles.ContentAlign, pageStyles.SectionSpace].join(" "),
        headerContentClass: pageStyles.h1,
        textContentClass: pageStyles.p2,
        subHeaderClass: pageStyles.p1,
      },
    },
    {
      type: AboutDynamicComponents.DividerHero,
      data: {
        headerContent: `We Work as One`,
        subHeaderContent: `We break down silos to build relationships that encourage meaningful communication`,
        textContent: `We are purposefully designed to be free from the trappings of large traditional agency networks to deliver more value to our clients. Our “One Team” approach offers clients improved agency communication, collaboration and accountability.  The approach allows for true partnership, helping to identify opportunities for business growth, strategies for creating efficiency, resulting in a cost-efficient agency model that combines best of large agency process with the best of small agency agility.`,
        className: [pageStyles.ContentAlign, pageStyles.SectionSpace].join(" "),
        headerContentClass: pageStyles.h1,
        textContentClass: pageStyles.p2,
        subHeaderClass: pageStyles.p1,
      },
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        className: [pageStyles.ContentAlign, pageStyles.SectionSpace].join(" "),
        headerContent: `Our Guiding Principles`,
        subHeaderContent: `We are committed to helping brands do good within the world we live and for the people who live in it`,
        headerContentClass: pageStyles.h1,
        textContentClass: pageStyles.p2,
        subHeaderClass: pageStyles.p1,
      },
    },
    {
      type: AboutDynamicComponents.Grid4,
      data: {
        className: [pageStyles.ContentAlign, pageStyles.SectionSpace].join(" "),
        cell1: {
          headerContent: `Boundless Creativity`,
          textContent: `Fresh thinking and bold ideas, brought to life through distinctive design and captivating storytelling.`,
          textContentClass: [pageStyles.p2].join(" "),
          className: styles.Cell,
          headerContentClass: [pageStyles.h2].join(" "),
        },
        cell2: {
          headerContent: `Insatiable Curiosity`,
          textContent: `Results-driven strategy based on business intelligence and rich, actionable insights about the world around us.`,
          textContentClass: [pageStyles.p2].join(" "),
          className: styles.Cell,
          headerContentClass: [pageStyles.h2].join(" "),
        },
        cell3: {
          headerContent: `Transparency`,
          textContent: `Relationships build on the spirit of openness, partnership and meaningful communication.`,
          textContentClass: [pageStyles.p2].join(" "),
          className: styles.Cell,
          headerContentClass: [pageStyles.h2].join(" "),
        },
        cell4: {
          headerContent: `Humanity`,
          textContent: `Cultivators of cultural inclusivity, recognizing the power of humanity to change the world for the better.`,
          textContentClass: [pageStyles.p2].join(" "),
          className: styles.Cell,
          headerContentClass: [pageStyles.h2].join(" "),
        },
      } as IGrid4Data,
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        headerContent: "What We Do",
        headerContentClass: [pageStyles.h1, pageStyles.ContentAlign].join(" "),
      },
    },
    {
      type: CoreDynamicComponents.Container,
      data: {
        className: [pageStyles.ContentAlign, styles.ServiceHeroWrapper].join(
          " "
        ),
        children: [
          {
            type: ServicesDynamicComponents.ServiceList,
            data: {
              style: { marginTop: "15px" },
              iconClickLink: Routes.Services,
              headerContentClass: [pageStyles.h2].join(" "),
              textContentClass: [pageStyles.p2].join(" "),
              servicesList: [
                {
                  isLargeIcon: false,
                  isLeftOriented: true,
                  headerContent: ServicesProvided.WebsiteDev.title,
                  textContent: ServicesProvided.WebsiteDev.description,
                  icon: ServicesProvided.WebsiteDev.icon,
                },
                {
                  isLargeIcon: false,
                  isLeftOriented: true,
                  headerContent: ServicesProvided.CRM.title,
                  textContent: ServicesProvided.CRM.description,
                  icon: ServicesProvided.CRM.icon,
                },
                {
                  isLargeIcon: false,
                  isLeftOriented: true,
                  headerContent: ServicesProvided.Advertising.title,
                  textContent: ServicesProvided.Advertising.description,
                  icon: ServicesProvided.Advertising.icon,
                },
              ],
            },
          },
          {
            type: CoreDynamicComponents.Button,
            data: {
              label: "Our Services",
              link: Routes.Services,
              className: [pageStyles.CenterAlign, styles.OurServicesBtn].join(
                " "
              ),
            },
          },
        ],
      },
    },
    { type: PageDynamicComponents.PageDivider },
    { type: PageDynamicComponents.PageFooter },
  ],
};

export default AboutConfig;
