import { CSSProperties, FunctionComponent, ReactNode } from "react";
import styles from "./Grid4Base.module.css";

export interface IGrid4Props {
  className?: string;
  cell1Element?: ReactNode;
  cell2Element?: ReactNode;
  cell3Element?: ReactNode;
  cell4Element?: ReactNode;
  style?: CSSProperties;
}

const Grid4Base: FunctionComponent<IGrid4Props> = ({
  className,
  cell1Element = null,
  cell2Element = null,
  cell3Element = null,
  cell4Element = null,
  style = {},
}) => {
  return (
    <div className={[styles.Grid4, className].join(" ")} style={style}>
      <section className={[styles.Cell].join(" ")}>{cell1Element}</section>

      <section className={[styles.Cell].join(" ")}>{cell2Element}</section>

      <section className={[styles.Cell].join(" ")}>{cell3Element}</section>

      <section className={[styles.Cell].join(" ")}>{cell4Element}</section>
    </div>
  );
};

export default Grid4Base;
