import { useState } from "react";
import styles from "./TileImagePlaceHolder.module.css";
export interface ICaseStudyTilePlaceHolder {
  color: string;
}
const colors = [
  "var(--pink-7)",
  "var(--orange-5)",
  "var(--green-8)",
  "var(--purple-6)",
  "var(--blue-6)",
  "var(--red-6)",
];
const getRandomeColor = () => {
  const index = Math.round(Math.random() * colors.length);
  return colors[index];
};

const TileImagePlaceHolder = () => {
  const [_color] = useState(getRandomeColor());
  return (
    <div
      className={styles.PlaceHolder}
      style={{ backgroundColor: _color }}
    ></div>
  );
};

export default TileImagePlaceHolder;
