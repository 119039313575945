import Routes from "../../../components/App/Routes";
import {
  CoreDynamicComponents,
  CSDynamicComponents,
  IDynamicCaseStudyPageConfig,
  IImageData,
  PageDynamicComponents,
} from "../../DynamicConfigTypes";
import { ServiceIcons } from "../../models/Services";
import { ICaseStudyHeroData } from "../../../components/CaseStudy/CaseStudyHero";
import { IImageHeroData } from "../../../components/CaseStudy/ImageHero";

import hero_mobi_src from "../../../assets/case_study/biosheer/hero_mobi.png";
import hero_src from "../../../assets/case_study/biosheer/hero.png";
import hero_logo_src from "../../../assets/case_study/biosheer/carousel_logo.png";
import p_skinny_src from "../../../assets/case_study/biosheer/p_hero1.png";
import p_img_src from "../../../assets/case_study/biosheer/p_hero2.png";
import p_img_src_2 from "../../../assets/case_study/biosheer/p_hero3.png";
import p_img_src_3 from "../../../assets/case_study/biosheer/p_hero4.png";
import p_img_src_4 from "../../../assets/case_study/biosheer/p_hero5.png";
import p_img_src_5 from "../../../assets/case_study/biosheer/p_hero6.png";
import p_img_src_6 from "../../../assets/case_study/biosheer/p_hero7.png";
import full_src from "../../../assets/case_study/biosheer/content_full.png";
import full_src_2 from "../../../assets/case_study/biosheer/content_full2.png";
import tile_src from "../../../assets/case_study/biosheer/tile.png";

import styles from "../../../pages/CaseStudy.module.css";
import pageStyles from "../../../components/Page/Page.module.css";
import { ICaseStudyTileData } from "../../../components/CaseStudy/TileList/CaseStudyTile";
import { ITextWithHeaderData } from "../../../components/Page/TextWithHeader";

const cs_title = "Biosheer";
const cs_route = Routes.Biosheer;
/**
 * The Hero reference.<br />
 * Used in the:<br />
 * * Home Case Studies Carousel<br />
 * * Works Case Studies Heroes List<br />
 * * Case Study Pages themselves<br />
 */
const cs_hero: ICaseStudyHeroData = {
  img: hero_src,
  mobi_img: hero_mobi_src,
  title: cs_title,
  description: `
  Helping women nurture their beauty at every age by educating them on the changing needs of their skin and how to fight the chronological signs of aging. <a href='${Routes.Biosheer}'>Read More</a>
`,
  services: [
    ServiceIcons.DesignBranding,
    ServiceIcons.SocialMedia,
    ServiceIcons.Advertising,
    ServiceIcons.MediaPlanning,
    ServiceIcons.WebsiteDev,
    ServiceIcons.CRM,
    ServiceIcons.ContentMarketing,
    ServiceIcons.ECommerce,
  ],
  logo: hero_logo_src,
  link: cs_route,
};

const cs_tile: ICaseStudyTileData = {
  img: tile_src,
  title: cs_title,
  subtitle:
    "Repositioning a health and beauty brand as a leader in helping women look their best.",
  link: cs_route,
};

/**
 * The Case Study Config used across the App.
 */
const BiosheerConfig: IDynamicCaseStudyPageConfig = {
  title: cs_title,
  slug: "biosheer", //TODO
  route: cs_route,
  isInHomeCarousel: false, //TODO
  hero: cs_hero,
  tile: cs_tile,
  data: [
    {
      type: PageDynamicComponents.WordMarkLink,
      data: {
        className: [pageStyles.CenterAlign, pageStyles.HomeLink].join(" "),
      },
    },
    {
      type: CSDynamicComponents.CaseStudyHero,
      data: {
        ...cs_hero,
        style: { marginTop: "30px" },
        titleClass: [pageStyles.h1].join(" "),
        descriptionClass: [pageStyles.p2].join(" "),
        featuredClass: [pageStyles.p2].join(" "),
        showReadMore: false,
        doesTitleLink: false,
      } as ICaseStudyHeroData,
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        className: [
          pageStyles.ThirdFourthAlign,
          styles.SectionVerticalSpace,
          pageStyles.AfterHeroFix, //TODO
        ].join(" "),
        headerContent: "Background",
        headerContentClass: [pageStyles.h1].join(" "),
        textContent: `
            For many women, the appearance of fine lines and wrinkles are unwelcome reminders of getting older.  Perpetuated by an industry that celebrates youth, for some women the effects of chronological aging go more than skin deep, affecting how they feel about themselves and their attractiveness as they age (with 1-5 women citing they worry about their age and appearance everyday).
            <br />
            <br />
            Offering the latest advancements in Biotechnology and Biochemistry, Biosheer was created to help women look and more importantly feel beautify at every stage of life, providing high-performance skin care collections scientifically developed and clinically proven to actually work.  
        `,
        textContentClass: [pageStyles.p2].join(" "),
      } as ITextWithHeaderData,
    },
    {
      type: CSDynamicComponents.ImageHeroSlim,
      data: {
        src: p_skinny_src,
        headerContent: `
            Bombarded by beauty brands, all  promising to firm this or smooth that, women were skeptical of product claims, having invested in products that failed to deliver desired results.
        `,
      } as IImageHeroData,
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        className: [
          pageStyles.ThirdFourthAlign,
          styles.SectionVerticalSpace,
        ].join(" "),
        headerContent: "Solution",
        headerContentClass: [pageStyles.h1].join(" "),
        textContent: `
        <p>When listening to the online dialogue surrounding women's health and beauty, we quickly realized the industry's obsession with youth was turning many older women off brands and products that were marketing directly to them. </p>
        <br />
        <p>After taking a long look in the mirror, we set out to change the narrative and celebrate beauty at every age. We partnered with trusted lifestyle and beauty influencers and got them to try Biosheer for themselves and tell us about their experience.</p>
        <br />
        <p>We then repositioned Biosheer in the market, revisiting how the brand communicated with women, not only depicting women of all ages in the advertising but by speaking to women plainly about how the needs of their skin changes over time.</p>
        `,
        textContentClass: pageStyles.p2,
        textWrapperClass: styles.ImageHeroTextWrapper,
      },
    },
    {
      type: CSDynamicComponents.ImageHeroRight,
      data: {
        src: p_img_src,
        headerContent: `
          Putting women's<br />skepticism to rest 
      `,
        textContent: `
          <p>Our first action was creating an optimized online experience that allowed women to discover Biosheer and put their skepticism to rest.  The site spoke to women plainly, giving them the reasons why they could believe Biosheer would help support their skincare journey at any age.  </p>
          <br />
          <p>We then facilitated their ability to shop from anywhere at anytime by offering a fully optimized, responsive E-comm experience. </p>
          `,
      } as IImageHeroData,
    },
    {
      type: CSDynamicComponents.ImageHeroLeft,
      data: {
        src: p_img_src_2,
        headerContent: `
        Fostering community<br />through conversation​
              `,
        textContent: `
              <p>Our first action was creating an optimized online experience that allowed women to discover Biosheer and put their skepticism to rest.  The site spoke to women plainly, giving them the reasons why they could believe Biosheer would help support their skincare journey at any age.  </p>
              <br />
              <p>We then facilitated their ability to shop from anywhere at anytime by offering a fully optimized, responsive E-comm experience. </p>
              `,
      },
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_src,
        alt: "",
      } as IImageData,
    },
    {
      type: CSDynamicComponents.ImageHeroRight,
      data: {
        src: p_img_src_3,
        textContent: `
                <p>We knew we had to overcome the obstacle of being just another beauty brand, claiming to have unlocked the secrets to beauty everlasting.  So, we looked to create skin care content in partnership with media properties and personalities that were already trusted in the space to tell it like it is.</p>
                <br />
                <p>Listen to the podcast <a href="https://www.listennotes.com/podcasts/she20/tick-tock-IwyBK_-4ufC/" target="_blank">here</a>.</p>
            `,
        headerContent: `
                Building trust through<br />trustworthy content
            `,
      },
    },
    {
      type: CSDynamicComponents.ImageHeroLeft,
      data: {
        src: p_img_src_4,
        textContent: `
                    <p>Endorsement from trusted health and beauty influencers goes a long way, especially when the women other women look to for advice are seen using your products.</p>
                    <br />
                    <p>So, we partnered with lifestyle and beauty influencers and got them to try Biosheer for themselves and tell us about their experiences using the product (reaching over a quarter of a million women.)  We also gave their following a chance to try Biosheer for themselves. </p>
                `,
        headerContent: `
                    Generating interest<br />amongst influencers
                `,
      },
    },
    {
      type: CSDynamicComponents.ImageHeroRight,
      data: {
        src: p_img_src_5,
        textContent: `
                    <p>To support beauty salon partners, we let their customers know they were still valued, (even if they couldn’t visit their local beauty bar during the pandemic lockdown).</p>
                    <br />
                    <p>We delivered self-care kits that included Biosheer samples and exclusive preferred-customer offers to help women take the best possible care of their skin, encouraging them to stick to their skin care routine, during a time that was anything but.</p>
                `,
        headerContent: `
                    Leveraging retail<br />trade partnerships
                `,
      },
    },
    {
      type: CSDynamicComponents.ImageHeroLeft,
      data: {
        src: p_img_src_6,
        textContent: `
                    <p>Keeping women engaged with the Biosheer brand, meant continuing to support them through their skin care journey.  To ensure Biosheer was positioned as a knowledgeable partner, we developed and delivered skin care content, offering women seasonal advice on how to take care of their skin.</p>
                    <br />
                    <p>Monthly email newsletters also included member-exclusive limited-time offers, to thank members for trusting their skin care to Biosheer and make taking the best possible care of their skin more accessible during the pandemic, when many women were scaling back on discretionary spending.</p>
                `,
        headerContent: `
        Encouraging loyalty<br />through value-based<br/>content
                `,
      },
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_src_2,
        alt: "",
      } as IImageData,
    },
    {
      type: CoreDynamicComponents.Button,
      data: {
        label: "See All Projects",
        link: Routes.Work,
        className: [pageStyles.CenterAlign, styles.SectionVerticalSpace].join(
          " "
        ),
      },
    },
    {
      type: PageDynamicComponents.PageDivider,
    },
    {
      type: PageDynamicComponents.PageFooter,
    },
  ],
};

export default BiosheerConfig;
