import styles from "./VideoCarousel.module.css";
import CarouselBasic from "./CarouselBasic";
import { FunctionComponent, useContext } from "react";
import AppContext from "../App/AppContext";
import { IBaseComponentData } from "../../config/DynamicConfigTypes";

import ReactPlayer from "react-player/youtube";

export interface IVideoCarouselItemData {
  video: string;
  mobi_video: string;
}
export interface IVideoCarouselData extends IBaseComponentData {
  videos: (IVideoCarouselItemData | undefined)[];
  backgroundImage: string;
  imgAlt: string;
}

export interface IVideoCarouselProps extends IVideoCarouselData {}

export const VideoCarousel: FunctionComponent<IVideoCarouselProps> = ({
  className = "",
  style = {},
  videos,
  backgroundImage = "",
  imgAlt = "",
}) => {
  const { isMobile } = useContext(AppContext);
  const slides = videos.map((video, index) => {
    if (video !== undefined) {
      return (
        <div className={styles.VideoCarouselItem} key={index}>
          <ReactPlayer
            url={isMobile ? video.mobi_video : video.video}
            className={styles.Video}
            controls={true}
          />
        </div>
      );
    } else {
      return null;
    }
  });

  return (
    <div className={styles.Wrapper}>
      <img
        className={styles.CarouselBackground}
        src={backgroundImage}
        alt={imgAlt}
      />
      <CarouselBasic
        className={className}
        style={style}
        children={slides as []}
        isHunterDouglas={true}
      ></CarouselBasic>
    </div>
  );
};

export default VideoCarousel;
