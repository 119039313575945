import { IDynamicCaseStudyPageConfig } from "../../config/DynamicConfigTypes";
import { ServiceIcons } from "../../config/models/Services";
import CaseStudiesConfig from "../../config/pages/caseStudies";

/**
 * A function to get all Case Studies.
 * @returns An array of all the Case Studies defined in the CaseStudyConfig.
 */
export const getCaseStudies = () => {
  return [...CaseStudiesConfig];
};

/**
 * A function to get all Hero objects from an array of Case Studies.
 *
 * Note: If called without an argument it will return heroes from all the Case Studies defined in the CaseStudyConfig.
 * @param caseStudies An Array of Case Studies to get the heroes from.
 * @returns An Array of hero objects.
 */
export const getHeroesFromCaseStudies = (
  caseStudies = [...CaseStudiesConfig]
) => {
  return caseStudies.map((caseStudy) => caseStudy.hero);
};
/**
 * A function to get all Tile objects from an array of Case Studies.
 *
 * Note: If called without an argument it will return tiles from all the Case Studies defined in the CaseStudyConfig.
 * @param caseStudies An Array of Case Studies to get the tiles from.
 * @returns An Array of tile objects.
 */
export const getTilesFromCaseStudies = (
  caseStudies = [...CaseStudiesConfig]
) => {
  return caseStudies.map((caseStudy) => caseStudy.tile);
};

/**
 * A function that filters out an Array of Case Studies based on an Array of Case Studies and target Array of Services provided as targets.
 *
 * Note: If called without arguments will return all Case Studies.
 * @param services Target Array of Services.
 * @param caseStudies Case Studies to check.
 * @returns An Array of Case Studies.
 */
export const getCaseStudiesForServices = (
  services: ServiceIcons[] = [],
  caseStudies = [...CaseStudiesConfig]
) => {
  const doesCaseStudyHaveServices = (
    caseStudy: IDynamicCaseStudyPageConfig
  ) => {
    const result = caseStudy.hero.services.some((service) => {
      return services.indexOf(service) !== -1;
    });
    return result;
  };
  return caseStudies.filter(doesCaseStudyHaveServices);
};
