import * as React from "react";
import styles from "./AboutHeroBase.module.css";
import { ReactComponent as Plus } from "../../../assets/Plus.svg";

export interface IAboutHeroBaseProps {
  className?: string;
  style?: React.CSSProperties;
  leftElement?: React.ReactNode;
  leftClassName?: string;
  rightElement?: React.ReactNode;
  rightClassName?: string;
}

export default function AboutHeroBase({
  className = "",
  leftElement = "",
  leftClassName = "",
  rightElement = "",
  rightClassName = "",
  style,
}: React.PropsWithChildren<IAboutHeroBaseProps>) {
  return (
    <div className={[styles.AboutHero, className].join(" ")} style={style}>
      <section className={[styles.Left, leftClassName].join(" ")}>
        {leftElement}
      </section>
      <Plus className={[styles.Plus].join(" ")} />
      <section className={[styles.Right, rightClassName].join(" ")}>
        {rightElement}
      </section>
    </div>
  );
}
