import { FC } from "react";
import { ScrollRestoration } from "react-router-dom";
import {
  AboutDynamicComponents,
  CoreDynamicComponents,
  CSDynamicComponents,
  HomeDynamicComponents,
  IBaseComponentData,
  IContainerData,
  IDynamicConfigElement,
  IDynamicPageConfig,
  IImageData,
  PageDynamicComponents,
  ServicesDynamicComponents,
} from "../../config/DynamicConfigTypes";
import BadgesHero, { IBadgesHeroData } from "../Badge/BadgesHero";
import CaseStudyCarousel, {
  ICaseStudyCarouselData,
} from "../Carousel/CaseStudyCarousel";
import HeroHeader from "../Home/HeroHeader";
import ServiceList, {
  IServiceListData,
} from "../Services/ServiceList/ServiceList";
import Btn, { IButtonData } from "./Btn";
import HomeLink, { IHomeLinkData } from "./HomeLink";
import styles from "./Page.module.css";
import csStyles from "../../pages/CaseStudy.module.css";
import TextWithHeader, { ITextWithHeaderData } from "./TextWithHeader";
import ColumnTextWithHeader, {
  IColumnTextWithHeaderData,
} from "./ColumnTextWithHeader";
import { ReactComponent as Divider } from "../../assets/Divider.svg";
import Footer from "./Footer";
import PageHeader, { IPageHeaderData } from "./PageHeader";
import CaseStudyHero, { ICaseStudyHeroData } from "../CaseStudy/CaseStudyHero";
import ImageHero, { IImageHeroData } from "../CaseStudy/ImageHero";
import VideoCarousel, { IVideoCarouselData } from "../Carousel/VideoCarousel";
import ImageHeroPopUp, {
  IImageHeroPopUpData,
} from "../CaseStudy/ImageHeroPopUp/ImageHeroPopUp";
import { AboutHero, DividerHero } from "../About";
import { IAboutHeroData } from "../About/AboutHero/AboutHero";
import { IDividerHeroData } from "../About/DividerHero/DividerHero";
import Grid4, { IGrid4Data } from "../About/Grid4/Grid4";
import FilteredTileList, {
  IFilteredTileListData,
} from "../CaseStudy/FilteredTileList/FilteredTileList";

export interface IPageComponentProps {
  config: IDynamicPageConfig;
}

const Page: FC<IPageComponentProps> = ({
  config = {
    title: "Page",
    slug: "page",
    data: [],
  },
}) => {
  const getElementsFromConfig = (elementConfigs: IDynamicConfigElement[]) => {
    return elementConfigs.map((elementConfig, index) => {
      let element = (
        <div
          key={index}
          className={[
            styles.ContentAlign,
            styles.FallBackElement,
            styles.p3,
          ].join(" ")}
        >
          *** Unrecognized Config Element ***
        </div>
      );
      const { type, data } = elementConfig;
      switch (type) {
        // Home
        case HomeDynamicComponents.HeroHeader:
          element = (
            <HeroHeader key={index} {...(data as IBaseComponentData)} />
          );
          break;
        case HomeDynamicComponents.CaseStudyCarousel:
          element = (
            <CaseStudyCarousel
              key={index}
              {...(data as ICaseStudyCarouselData)}
            />
          );
          break;
        case HomeDynamicComponents.BadgesHero:
          element = <BadgesHero key={index} {...(data as IBadgesHeroData)} />;
          break;

        // Services
        case ServicesDynamicComponents.ServiceList:
          element = <ServiceList key={index} {...(data as IServiceListData)} />;
          break;

        // Page
        case PageDynamicComponents.PageDivider:
          element = (
            <Divider
              key={index}
              {...(data as IBaseComponentData)}
              className={[
                styles.CenterAlign,
                styles.Divider,
                styles.DividerSpace,
              ].join(" ")}
            />
          );
          break;
        case PageDynamicComponents.PageFooter:
          element = <Footer key={index} {...(data as IBaseComponentData)} />;
          break;
        case PageDynamicComponents.PageHeader:
          element = (
            <PageHeader key={index}>
              {(data as IPageHeaderData).content}
            </PageHeader>
          );
          break;
        case PageDynamicComponents.BunnyLink:
          element = (
            <HomeLink
              key={index}
              {...(data as IHomeLinkData)}
              isWordMark={false}
            />
          );
          break;
        case PageDynamicComponents.WordMarkLink:
          element = (
            <HomeLink
              key={index}
              {...(data as IHomeLinkData)}
              isWordMark={true}
            />
          );
          break;
        case PageDynamicComponents.TextWithHeader:
          element = (
            <TextWithHeader key={index} {...(data as ITextWithHeaderData)} />
          );

          break;
        case PageDynamicComponents.ColumnTextWithHeader:
          element = (
            <ColumnTextWithHeader {...(data as IColumnTextWithHeaderData)} />
          );
          break;
        case PageDynamicComponents.VideoCarousel:
          element = <VideoCarousel {...(data as IVideoCarouselData)} />;
          break;
        case CSDynamicComponents.CaseStudyHero:
          element = (
            <CaseStudyHero key={index} {...(data as ICaseStudyHeroData)} />
          );
          break;
        case PageDynamicComponents.ImageHero:
          element = <ImageHero key={index} {...(data as IImageHeroData)} />;
          break;

        // CaseStudy
        case CSDynamicComponents.ImageHeroSlim:
          element = (
            <ImageHero
              key={index}
              {...(data as IImageHeroData)}
              imgClass={csStyles.LeftSkinnyImage}
              className={[
                styles.ThirdFourthAlign,
                csStyles.ImageHeroLeft,
                csStyles.SectionVerticalSpace,
              ].join(" ")}
              headerContentClass={styles.PullQuote}
              textContentClass={styles.p2}
              textWrapperClass={csStyles.ImageHeroTextWrapper}
            />
          );
          break;
        case CSDynamicComponents.ImageHeroLeft:
          element = (
            <ImageHero
              key={index}
              {...(data as IImageHeroData)}
              className={[
                styles.ContentAlign,
                csStyles.ImageHeroLeft,
                csStyles.SectionVerticalSpace,
              ].join(" ")}
              imgClassWrapper={csStyles.ImageHeroImage}
              textContentClass={styles.p2}
              headerContentClass={styles.h2}
              textWrapperClass={csStyles.ImageHeroTextWrapper}
            />
          );
          break;
        case CSDynamicComponents.ImageHeroRight:
          element = (
            <ImageHero
              key={index}
              {...(data as IImageHeroData)}
              className={[
                styles.ContentAlign,
                csStyles.ImageHeroRight,
                csStyles.SectionVerticalSpace,
              ].join(" ")}
              imgClassWrapper={csStyles.RightBleedImage}
              headerContentClass={styles.h2}
              textContentClass={styles.p2}
              textWrapperClass={csStyles.ImageHeroRightBleedTextWrapper}
            />
          );
          break;
        case CSDynamicComponents.ImageHeroPopUpLeft:
          element = (
            <ImageHeroPopUp
              key={index}
              {...(data as IImageHeroPopUpData)}
              className={[
                styles.ContentAlign,
                csStyles.SectionVerticalSpace,
              ].join(" ")}
              imageHeroClass={csStyles.ImageHeroLeft}
              imgClassWrapper={csStyles.ImageHeroImage}
              textContentClass={styles.p2}
              headerContentClass={styles.h2}
              textWrapperClass={csStyles.ImageHeroTextWrapper}
            />
          );
          break;
        case CSDynamicComponents.ImageHeroPopUpRight:
          element = (
            <ImageHeroPopUp
              key={index}
              {...(data as IImageHeroPopUpData)}
              className={[
                styles.ContentAlign,
                csStyles.SectionVerticalSpace,
              ].join(" ")}
              imageHeroClass={csStyles.ImageHeroRight}
              imgClassWrapper={csStyles.RightBleedImage}
              textContentClass={styles.p2}
              headerContentClass={styles.h2}
              textWrapperClass={csStyles.ImageHeroRightBleedTextWrapper}
            />
          );
          break;
        case CSDynamicComponents.CaseStudyFullBleedImage:
          element = (
            <img
              key={index}
              {...(data as IImageData)}
              className={[
                styles.FillContentImage,
                (data as IImageData).className,
              ].join(" ")}
              alt=""
            />
          );
          break;
        case CSDynamicComponents.FilteredTileList:
          element = (
            <FilteredTileList
              key={index}
              {...(data as IFilteredTileListData)}
            />
          );
          break;

        // About
        case AboutDynamicComponents.AboutHero:
          element = <AboutHero key={index} {...(data as IAboutHeroData)} />;
          break;
        case AboutDynamicComponents.DividerHero:
          element = <DividerHero key={index} {...(data as IDividerHeroData)} />;
          break;
        case AboutDynamicComponents.Grid4:
          element = <Grid4 key={index} {...(data as IGrid4Data)} />;
          break;
        // Core
        case CoreDynamicComponents.Button:
          element = <Btn key={index} {...(data as IButtonData)} />;
          break;
        case CoreDynamicComponents.Container:
          const subEls = getElementsFromConfig(
            (data as IContainerData).children
          );
          element = (
            <div
              key={index}
              className={(data as IBaseComponentData)?.className}
              style={(data as IBaseComponentData)?.style}
            >
              {subEls}
            </div>
          );
          break;
      }
      return element;
    });
  };
  const elements = getElementsFromConfig(config.data);
  return (
    <div className={styles.Page}>
      <ScrollRestoration />
      {elements}
    </div>
  );
};

export default Page;
