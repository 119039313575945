import styles from "./Footer.module.css";
import officeSrc from "../../assets/Office.png";
import { ReactComponent as WordMark } from "../../assets/S_M_WORDMARK.svg";
import { useContext } from "react";
import AppContext from "../App/AppContext";
import SocialIcons from "./SocialIcons";
import { Link } from "react-router-dom";
import pageStyles from "../../components/Page/Page.module.css";
import { IBaseComponentData } from "../../config/DynamicConfigTypes";
export interface IFooterProps extends IBaseComponentData {}
const Footer: React.FC<IFooterProps> = (props) => {
  const { isMobile } = useContext(AppContext);
  return (
    <footer
      className={[styles.Footer, props.className].join(" ")}
      style={props.style}
    >
      <div className={styles.Overlay} />
      <section className={styles.FooterLeft}>
        <h1 className={pageStyles.Jumbo}>Say Hello</h1>
        <div className={[styles.FooterDetails].join(" ")}>
          <p className={pageStyles.h3}>New Business Inquiries</p>
          <div
            className={[styles.FooterDetailsContact, pageStyles.p2].join(" ")}
          >
            <div>sayhello@smithandmason.co</div>
            <div>416.727.6476</div>
          </div>
        </div>
        <div className={styles.FooterDetails}>
          <p className={pageStyles.h3}>Careers</p>
          <div
            className={[styles.FooterDetailsContact, pageStyles.p2].join(" ")}
          >
            <div>careers@smithandmason.co</div>
            <div>416.466.4251</div>
          </div>
        </div>

        <SocialIcons className={styles.SocialIcons} />
      </section>
      <section className={styles.FooterRight}>
        <img src={officeSrc} alt="The office" />
        <div className={[styles.FooterDetails].join(" ")}>
          <WordMark className={styles.Logo} />
          <div className={[styles.OfficeLocation, pageStyles.p3].join(" ")}>
            468 Queen St. East {isMobile ? <br /> : ""}Suite 500 Toronto ON, M5A
            1T7
          </div>
          <div className={[styles.Copy, pageStyles.p3].join(" ")}>
            <p>© Copyright Smith+Mason co. 2023</p>
            <Link to="/privacy">Privacy Policy</Link>
          </div>
        </div>
      </section>
    </footer>
  );
};
export default Footer;
