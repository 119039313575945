import { useState } from 'react';
import { Parallax } from 'react-scroll-parallax';
import { ReactComponent as HeroHeaderBG } from '../../assets/HeroHeaderBG.svg';
import { ReactComponent as HeroLogo } from '../../assets/S+M_Logo.svg';
import styles from './HeroHeader.module.css';
import { ReactComponent as Arrow } from '../../assets/DownArrow.svg';

type HeroHeaderProps = {
  name?: string;
  className?: string;
};

const defaultProps: HeroHeaderProps = {
  name: 'HeroHeaderDefaultName',
  className: '',
};

const HeroHeader: React.FC<HeroHeaderProps> = props => {
  const [_isOnScreen, _setIsOnScreen] = useState(false);
  const [_isScrollingUp, _setIsScrollingUp] = useState(false);
  const [_lastProgress, _setLastProgress] = useState(0);
  const backgroundVectorStyles = (
    _isOnScreen
      ? [styles.BackgroundVector]
      : [styles.BackgroundVector, styles.BackgroundVectorOffScreen]
  ).join(' ');
  const foregroundStyles = (
    _isOnScreen
      ? [styles.Foreground]
      : [styles.Foreground, styles.ForegroundOffScreen]
  ).join(' ');
  const onProgress = (progress: number) => {
    console.log(progress);
    progress > 0.9 || progress < 0.2
      ? _setIsOnScreen(false)
      : _setIsOnScreen(true);
    if (_lastProgress !== progress) {
      if (_lastProgress > progress) {
        _setIsScrollingUp(true);
      } else {
        _setIsScrollingUp(false);
      }

      _setLastProgress(progress);
    }
  };
  return (
    <Parallax
      onProgressChange={onProgress}
      className={`${props.className} ${styles.HeroHeader}`}
    >
      <div className={styles.Background}>
        <HeroHeaderBG className={backgroundVectorStyles} />
      </div>
      <div className={foregroundStyles}>
        <HeroLogo className={styles.HeroLogo} />
      </div>
      <Arrow
        className={[
          styles.DownArrow,
          _isOnScreen ? styles.bounce : styles.fadeOut,
        ].join(' ')}
      />
    </Parallax>
  );
};
HeroHeader.defaultProps = defaultProps;
export default HeroHeader;
