import Routes from "../../../components/App/Routes";
import {
  CoreDynamicComponents,
  CSDynamicComponents,
  IDynamicCaseStudyPageConfig,
  IImageData,
  PageDynamicComponents,
} from "../../DynamicConfigTypes";
import { ServiceIcons } from "../../models/Services";
import { ICaseStudyHeroData } from "../../../components/CaseStudy/CaseStudyHero";
import { IImageHeroData } from "../../../components/CaseStudy/ImageHero";

import hero_mobi_src from "../../../assets/case_study/thedrop/hero_mobi.png";
import hero_src from "../../../assets/case_study/thedrop/hero.png";
import hero_logo_src from "../../../assets/case_study/thedrop/carousel_logo.png";
import Drop_p_skinny from "../../../assets/case_study/thedrop/p_hero1.png";
import p_img_src from "../../../assets/case_study/thedrop/p_hero2.png";
import p_img_src_2 from "../../../assets/case_study/thedrop/p_hero3.png";
import p_img_src_3 from "../../../assets/case_study/thedrop/p_hero4.png";
import p_img_src_4 from "../../../assets/case_study/thedrop/p_hero5.png";
import p_img_src_5 from "../../../assets/case_study/thedrop/p_hero6.png";
import full_src from "../../../assets/case_study/thedrop/content_full.png";
import full_src_2 from "../../../assets/case_study/thedrop/content_full2.png";
import tile_src from "../../../assets/case_study/thedrop/tile.png";

import styles from "../../../pages/CaseStudy.module.css";
import pageStyles from "../../../components/Page/Page.module.css";
import { ICaseStudyTileData } from "../../../components/CaseStudy/TileList/CaseStudyTile";
import { ITextWithHeaderData } from "../../../components/Page/TextWithHeader";

const cs_title = "The Drop Collective";
const cs_route = Routes.Drop;
/**
 * The Hero reference.<br />
 * Used in the:<br />
 * * Home Case Studies Carousel<br />
 * * Works Case Studies Heroes List<br />
 * * Case Study Pages themselves<br />
 */
const cs_hero: ICaseStudyHeroData = {
  img: hero_src,
  mobi_img: hero_mobi_src,
  title: cs_title,
  description: `
      Giving Canadian whisky enthusiasts and collectors the drop on getting their hands on the best drams from the best distillers in the world. <a href='${Routes.Drop}'>Read More</a>`,
  services: [
    ServiceIcons.DesignBranding,
    ServiceIcons.SocialMedia,
    ServiceIcons.Advertising,
    ServiceIcons.MediaPlanning,
    ServiceIcons.CRM,
  ],
  logo: hero_logo_src,
  link: cs_route,
};

const cs_tile: ICaseStudyTileData = {
  img: tile_src,
  title: cs_title,
  subtitle:
    "Developing a robust ecosystem surrounding Canadians with a world of whisky content.",
  link: cs_route,
};

/**
 * The Case Study Config used across the App.
 */
const DropConfig: IDynamicCaseStudyPageConfig = {
  title: cs_title,
  slug: "the-drop-collective", //TODO
  route: cs_route,
  isInHomeCarousel: true, //TODO
  hero: cs_hero,
  tile: cs_tile,
  data: [
    {
      type: PageDynamicComponents.WordMarkLink,
      data: {
        className: [pageStyles.CenterAlign, pageStyles.HomeLink].join(" "),
      },
    },
    {
      type: CSDynamicComponents.CaseStudyHero,
      data: {
        ...cs_hero,
        style: { marginTop: "30px" },
        titleClass: [pageStyles.h1].join(" "),
        descriptionClass: [pageStyles.p2].join(" "),
        featuredClass: [pageStyles.p2].join(" "),
        showReadMore: false,
        doesTitleLink: false,
      } as ICaseStudyHeroData,
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        className: [
          pageStyles.ThirdFourthAlign,
          styles.SectionVerticalSpace,
          pageStyles.AfterHeroFix, //TODO
        ].join(" "),
        headerContent: "Background",
        headerContentClass: [pageStyles.h1].join(" "),
        textContent: `
            Corby Spirit and Wine Limited is a leading Canadian marketer and distributor of spirits and wines, affiliated with Pernod Ricard, a global leader in the category.
            <br />
            <br />
            Demand for premium spirits continues to grow steadily, with more and more people rediscovering the joys of entertaining at home and the simple pleasure of a enjoying a glass to unwind at the end of the day. 
            <br />
            <br />
            Corby offers an extensive portfolio of premium scotches, ryes and bourbons from distillers from across the world.  And while research told us that many Canadians were interested in improving their appreciation and knowledge of Whisky, for many would be collectors, the world of premium whisky seemed intimidating, old and exclusionary.`,
        textContentClass: [pageStyles.p2].join(" "),
      } as ITextWithHeaderData,
    },
    {
      type: CSDynamicComponents.ImageHeroSlim,
      data: {
        src: Drop_p_skinny,
        headerContent: `‘Too much of anything is bad, but too much good whisky is barely enough.’`,
        textContent: `- Mark Twain`,
      } as IImageHeroData,
    },
    {
      type: PageDynamicComponents.TextWithHeader,
      data: {
        className: [
          pageStyles.ThirdFourthAlign,
          styles.SectionVerticalSpace,
        ].join(" "),
        headerContent: "Solution",
        headerContentClass: [pageStyles.h1].join(" "),
        textContent: `We created a consumer-centric platform, aimed at introducing and educating  consumers about the different types of whisky and facilitating discovery and sales of Corby new drams and distilleries.  We called this, The Drop Collective - giving consumers the drop on upcoming limited releases and award-winning whiskies from around the world. 
        <br />
        <br />
        We started by developing brand foundations and a visual design language for the program, building a brand that felt premium yet approachable.  We then architected the integrated brand experience. The program included CRM, social and retail presence and an online destination that supported featured whiskies with product information and behind-the-scenes content about the distilleries and distillers behind the world’s best whiskies. 
        <br />
        <br />
        Focusing on the craft of whisky-making, we took inspiration from craft distillers and designed a content plan that put the spotlight on them and built desirability for the products they make. 
        `,
        textContentClass: pageStyles.p2,
        textWrapperClass: styles.ImageHeroTextWrapper,
      },
    },
    {
      type: CSDynamicComponents.ImageHeroRight,
      data: {
        src: p_img_src,
        textContent: `To attract whisky enthusiasts to the brand, we knew that not only did we need to provide great content, but it needed to come from a brand that felt as premium as the whiskies it represented, while still feeling accessible. 
          <br />
          <br />
          Taking inspiration from the iconic copper pots and oak barrels used in the production of fine whiskies, and layering in vibrant, contemporary colours, we created a design system that had the ability to appeal to both the most staunch whisky traditionalist and new, younger audiences who just discovered an appreciation for whisky. 
          `,
        headerContent: `Differentiating<br />The Drop`,
      } as IImageHeroData,
    },
    {
      type: CSDynamicComponents.ImageHeroLeft,
      data: {
        src: p_img_src_2,
        textContent: `We set out to create an online destination that helped visitors increase their whisky knowledge and prowess and act as a source of discovery.  We provided the strategy, design, content and development of the online property to create a user experience bringing the world’s best whiskies to people’s desktops and mobile devices.
          `,
        headerContent: `Facilitating product discovery & education`,
      },
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_src,
        alt: "",
      } as IImageData,
    },
    {
      type: CSDynamicComponents.ImageHeroRight,
      data: {
        src: p_img_src_3,
        headerContent: `Putting the call out to whisky lovers`,
        textContent: `With whisky growing in popularity, new audiences and segments were discovering and developing an appreciation for whisky all the time.  This meant we couldn’t rely on traditional ways of thinking about who our target audience was and how to best reach them.  
          <br />
          <br />
          We targeted consumers based on expressed interest data and built look-alike audience segments of similar consumer profiles to reach new users.  We also pixeled visitors to owned whisky properties and retargeted them with ads to get them to sign-up to The Drop Collective, giving them the drop on rare whiskies hitting the market. 
          `,
      },
    },
    {
      type: CSDynamicComponents.ImageHeroLeft,
      data: {
        src: p_img_src_4,
        headerContent: `Delivering on the brand’s promise`,
        textContent: `Once people signed-up, the real work began.  We had to ensure that we were delivering the goods and giving consumers the drop on new and exclusive releases.  
        <br />
        <br />
        Email communications were sent to the database, informing them of rare finds, exclusive events and giving them access to behind-the-scenes content and interviews with the distillers – who we discovered have personalities as unique and  flavourful as the whiskies they make. 
        <br />
        <br />
        See for yourself what all the buzz is about.  <a href="https://thedropcollective.ca/sign-up/" target="_blank">Sign-up today</a>.
        `,
      },
    },
    {
      type: CSDynamicComponents.ImageHeroRight,
      data: {
        src: p_img_src_5,
        headerContent: `Enabling discovery at retailers’ shelves`,
        textContent: `We created program-specific display systems to house featured whiskies at retail, encouraging shoppers to sign-up to receive email communications from The Drop Collective.  
        <br />
        <br />
        Branded shelving systems were created that allowed featured brands to be easily swapped out.  Small-format informational brochures about the program were affixed to the necks of the featured bottles, giving people a taste of what they could expect to receive when they signed up.  And the inclusion of QR codes allowed people to use their mobile phones to sign-up on the spot. 
        `,
      },
    },
    {
      type: CSDynamicComponents.CaseStudyFullBleedImage,
      data: {
        src: full_src_2,
        alt: "",
      } as IImageData,
    },
    {
      type: CoreDynamicComponents.Button,
      data: {
        label: "See All Projects",
        link: Routes.Work,
        className: [pageStyles.CenterAlign, styles.SectionVerticalSpace].join(
          " "
        ),
      },
    },
    {
      type: PageDynamicComponents.PageDivider,
    },
    {
      type: PageDynamicComponents.PageFooter,
    },
  ],
};

export default DropConfig;
