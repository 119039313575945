import { CSSProperties } from "react";
import { Parallax } from "react-scroll-parallax";
import { IBaseComponentData } from "../../config/DynamicConfigTypes";
import { useCaptureLinkClicksFromHTML } from "../../hooks/useCaptureLinkClicks";
import styles from "./TextWithHeader.module.css";

export interface ITextWithHeaderData extends IBaseComponentData {
  headerContent?: string;
  headerContentClass?: string;
  headerContentStyle?: CSSProperties;
  subHeaderContent?: string;
  subHeaderClass?: string;
  subHeaderStyle?: CSSProperties;
  textContent?: string;
  textContentClass?: string;
  textContentStyle?: CSSProperties;
}
export interface ITextWithHeaderProps extends ITextWithHeaderData {}

const TextWithHeader: React.FC<ITextWithHeaderProps> = ({
  className = "",
  headerContent,
  headerContentClass,
  textContent,
  textContentClass,
  subHeaderContent,
  subHeaderClass,
  style,
  textContentStyle,
  headerContentStyle,
  subHeaderStyle,
}) => {
  const captureLinkClicks = useCaptureLinkClicksFromHTML();
  const header = (
    <div
      className={headerContentClass}
      dangerouslySetInnerHTML={{ __html: headerContent || "" }}
      onClick={captureLinkClicks}
      style={headerContentStyle}
    />
  );
  const subHeader = (
    <div
      className={subHeaderClass}
      dangerouslySetInnerHTML={{ __html: subHeaderContent || "" }}
      onClick={captureLinkClicks}
      style={subHeaderStyle}
    />
  );
  const text = (
    <div
      className={textContentClass}
      dangerouslySetInnerHTML={{ __html: textContent || "" }}
      style={textContentStyle}
      onClick={captureLinkClicks}
    />
  );
  return (
    <Parallax className={`${styles.TextWithHeader} ${className}`} style={style}>
      {headerContent && headerContent !== "" ? header : null}
      {subHeaderContent && subHeaderContent !== "" ? subHeader : null}
      {textContent && textContent !== "" ? text : null}
    </Parallax>
  );
};

export default TextWithHeader;
