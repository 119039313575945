import { createContext } from 'react';

const AppContext = createContext({
  isDarkMode: true,
  setIsDarkMode: (isDark: boolean) => {},
  isMenuOpen: false,
  setIsMenuOpen: (isOpen: boolean) => {},
  isMobile: false,
});

export default AppContext;
