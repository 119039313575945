import { CSSProperties, FC } from "react";
import CaseStudyTile, { ICaseStudyTileData } from "./CaseStudyTile";
import styles from "./CaseStudyTileList.module.css";
import pageStyles from "../../Page/Page.module.css";

export interface ICaseStudyTileListProps {
  className?: string;
  style?: CSSProperties;
  data: ICaseStudyTileData[];
  tilesVisible?: number[];
  tileTitleClassName?: string;
  tileTitleStyle?: CSSProperties;
  tileSubtitleClassName?: string;
  tileSubtitleStyle?: CSSProperties;
  tileImgClassName?: string;
  tileImgStyle?: CSSProperties;
}
const CaseStudyTileList: FC<ICaseStudyTileListProps> = ({
  className = "",
  style = {},
  data = [],
  tilesVisible = [],
}) => {
  let tiles = data.map((tileData, index) => {
    const isVisible = tilesVisible.indexOf(index) !== -1;
    console.log(`Tile ${index} ${tileData.title}`, tilesVisible, isVisible);
    return (
      <CaseStudyTile
        key={index}
        {...tileData}
        className={[
          styles.ListItem,
          styles.fadeInOut,
          styles.grow,
          isVisible ? "" : styles.hidden,
        ].join(" ")}
      />
    );
  });
  if (!tilesVisible.length) {
    tiles = [
      <div className={[pageStyles.h3, styles.NoResults].join(" ")}>
        No Case Studies for selected services. We are working on adding more
        examples of our work. Check again later.
      </div>,
    ];
  }
  return (
    <div className={[styles.List, className].join(" ")} style={style}>
      {tiles}
    </div>
  );
};

export default CaseStudyTileList;
