import { useContext, useState } from 'react';
import { Outlet } from 'react-router';
import AppContext from './AppContext';
import AppMenu from './AppMenu';
import styles from './AppUI.module.css';

const AppUI = () => {
  const appBodyClassNames = [styles.AppBody].join(' ');
  return (
    <div id="appBody" className={appBodyClassNames}>
      <AppMenu />
      <div className={[styles.AppContent].join(' ')}>
        <Outlet />
      </div>
    </div>
  );
};

export default AppUI;
