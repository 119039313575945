import { FC } from "react";
import { IBaseComponentData } from "../../../config/DynamicConfigTypes";
import TextWithHeader, { ITextWithHeaderData } from "../../Page/TextWithHeader";
import Grid4Base, { IGrid4Props } from "./Grid4Base";

export interface IGrid4Data extends IBaseComponentData {
  cell1: ITextWithHeaderData;
  cell2: ITextWithHeaderData;
  cell3: ITextWithHeaderData;
  cell4: ITextWithHeaderData;
}

const Grid4: FC<IGrid4Data> = (props) => {
  const cell1 = <TextWithHeader {...props.cell1} />;
  const cell2 = <TextWithHeader {...props.cell2} />;
  const cell3 = <TextWithHeader {...props.cell3} />;
  const cell4 = <TextWithHeader {...props.cell4} />;
  const grid4Props: IGrid4Props = {
    className: props.className,
    style: props.style,
    cell1Element: cell1,
    cell2Element: cell2,
    cell3Element: cell3,
    cell4Element: cell4,
  };
  return <Grid4Base {...grid4Props} />;
};

export default Grid4;
