export enum ServiceIcons {
  Advertising = "Advertising",
  ContentMarketing = "ContentMarketing",
  CRM = "CRM",
  DigitalSolutions = "DigitalSolutions",
  DesignBranding = "DesignBranding",
  ECommerce = "ECommerce",
  MediaPlanning = "MediaPlanning",
  SocialMedia = "SocialMedia",
  WebsiteDev = "WebsiteDev",
}

export interface IServiceProvided {
  icon: ServiceIcons;
  title: string;
  description: string;
}

export const ServicesProvided: { [key in ServiceIcons]: IServiceProvided } = {
  DesignBranding: {
    icon: ServiceIcons.DesignBranding,
    title: "Design and Branding",
    description:
      "We offer full branding, rebranding, positioning, and visual design systems for clients wanting to get noticed. As a core competency, we immerse ourselves into each branding element: colour, language, iconography, photography, templates, collateral, and style guides -all wrapped up in a comprehensive, practical, and beautiful brand design system.",
  },
  Advertising: {
    icon: ServiceIcons.Advertising,
    title: "Advertising",
    description:
      "Advertising keeps changing, but ideas remain at the core of what we do. From traditional broadcast, print and out of home to fully digital social marketing, we cover the spectrum. All backed by solid strategic insight and planning.",
  },
  SocialMedia: {
    icon: ServiceIcons.SocialMedia,
    title: "Social Media Management",
    description:
      "We grow social communities from the ground up. From content strategy, analytics and day to day channel monitoring, management and reporting, your social presence is in good hands.",
  },
  ContentMarketing: {
    icon: ServiceIcons.ContentMarketing,
    title: "Content Marketing",
    description:
      "Story telling is something we love to do. Blog writing, newsletters, articles and of course all things social are all part of our day to day. Captivating our audiences with interesting stories and content that elevates brands.",
  },
  MediaPlanning: {
    icon: ServiceIcons.MediaPlanning,
    title: "Media Planning",
    description:
      "We bring brands to where their customers are. It’s about time, place, and message. Together with our media partner, we deliver sound media strategies – the right mix of channels, to help brands get noticed and sales made.",
  },
  WebsiteDev: {
    icon: ServiceIcons.WebsiteDev,
    title: "Website Development",
    description:
      "Services include content audits, competitive analyses, and the invaluable discipline of user experience. Before any pixel is pushed, we plan and plan again. So, when design takes over, our client’s dot com is spot on perfect.",
  },
  CRM: {
    icon: ServiceIcons.CRM,
    title: "CRM",
    description:
      "It isn’t email or data capture, our approach to CRM is based on relationships. So content, copywriting, design, and distribution are tailored to each of our clients’ needs. Helping brands remain at the foremost of their customers’ minds and in high regard.",
  },
  ECommerce: {
    icon: ServiceIcons.ECommerce,
    title: "eCommerce",
    description:
      "We believe eCommerce is about problem solving. How to get your product into shopping carts and into happy customers’ hands. Each case is unique, there is no one size fits all, so we craft eCom strategies that include data driven SEO strategies. We will work within your existing eCom framework or develop net new solutions to increase your customers’ basket size at checkout.",
  },
  DigitalSolutions: {
    icon: ServiceIcons.DigitalSolutions,
    title: "Digital Solutions",
    description:
      "We are a collective of digital natives and thinkers. We see our clients’ needs as invitations to explore different avenues to achieve the desired results. We seek out the best digital options, from apps to full eCommerce builds, to make a difference in our clients’ and their customers’ lives.",
  },
};
