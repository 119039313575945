import { FunctionComponent, PropsWithChildren } from "react";
import { IBaseComponentData } from "../../config/DynamicConfigTypes";
import pageStyles from "./Page.module.css";

export interface IPageHeaderData extends IBaseComponentData {
  content: string;
}

const PageHeader: FunctionComponent<PropsWithChildren> = (props) => {
  return (
    <div
      className={[
        pageStyles.ContentAlign,
        pageStyles.PageHeader,
        pageStyles.Jumbo,
        pageStyles.SectionSpace,
      ].join(" ")}
    >
      {props.children}
    </div>
  );
};

export default PageHeader;
