import { FC, useContext } from "react";
import { IBaseComponentData } from "../../config/DynamicConfigTypes";
import AppContext from "../App/AppContext";
import Badge, { Badges } from "./Badge";
import styles from "./BadgesHero.module.css";
export interface IBadgesHeroData extends IBaseComponentData {
  badges: Badges[];
}
export interface IBadgesHeroProps extends IBadgesHeroData {}
const BadgesHero: React.FC<IBadgesHeroProps> = (props) => {
  const { isMobile } = useContext(AppContext);
  const badges = props.badges || [];
  const badgesComps = badges.map((badge, index) => {
    let order = index;
    if (isMobile && (index - 2) % 4 === 0) {
      order = index + 1;
    }
    if (isMobile && (index - 2) % 4 === 1) {
      order = index - 1;
    }
    return <Badge badge={badge} key={index} style={{ order: order }} />;
  });
  return (
    <div
      className={[styles.BadgesHero, props.className].join(" ")}
      style={props.style}
    >
      {badgesComps}
    </div>
  );
};
export default BadgesHero;
