import ServiceListItem, { IServiceListItemData } from "./ServiceListItem";
import styles from "./ServiceList.module.css";
import { IBaseComponentData } from "../../../config/DynamicConfigTypes";
import { CSSProperties, FC } from "react";
export interface IServiceListData extends IBaseComponentData {
  headerContentClass?: string;
  headerContentStyle?: CSSProperties;
  textContentClass?: string;
  textContentStyle?: CSSProperties;
  servicesList?: IServiceListItemData[];
  iconClickLink?: string;
}

export interface IServiceListProps extends IServiceListData {}

const ServiceList: FC<IServiceListProps> = ({
  servicesList = [],
  className,
  style,
  headerContentClass,
  headerContentStyle,
  textContentClass,
  textContentStyle,
  iconClickLink,
}) => {
  const itemList = servicesList.map(
    (serviceItemData: IServiceListItemData, index) => {
      return (
        <ServiceListItem
          isLeftOriented={serviceItemData.isLeftOriented}
          isLargeIcon={serviceItemData.isLargeIcon}
          icon={serviceItemData.icon}
          iconClickLink={serviceItemData.iconClickLink || iconClickLink}
          key={index}
          headerContent={serviceItemData.headerContent}
          headerContentClass={
            serviceItemData.headerContentClass || headerContentClass
          }
          headerContentStyle={
            serviceItemData.headerContentStyle || headerContentStyle
          }
          textContent={serviceItemData.textContent}
          textContentClass={
            serviceItemData.textContentClass || textContentClass
          }
          textContentStyle={
            serviceItemData.textContentStyle || textContentStyle
          }
        />
      );
    }
  );

  return (
    <div className={[styles.ServiceList, className].join(" ")} style={style}>
      {itemList}
    </div>
  );
};

export default ServiceList;
