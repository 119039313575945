import { CSSProperties } from "react";
import { IAboutHeroData } from "../components/About/AboutHero/AboutHero";
import { IDividerHeroData } from "../components/About/DividerHero/DividerHero";
import { IBadgesHeroData } from "../components/Badge/BadgesHero";
import { ICaseStudyCarouselData } from "../components/Carousel/CaseStudyCarousel";
import { ICaseStudyHeroData } from "../components/CaseStudy/CaseStudyHero";
import { IFilteredTileListData } from "../components/CaseStudy/FilteredTileList/FilteredTileList";
import { IImageHeroData } from "../components/CaseStudy/ImageHero";
import { IImageHeroPopUpData } from "../components/CaseStudy/ImageHeroPopUp/ImageHeroPopUp";
import { ICaseStudyTileData } from "../components/CaseStudy/TileList/CaseStudyTile";
import { IButtonData } from "../components/Page/Btn";
import { IHomeLinkData } from "../components/Page/HomeLink";
import { IPageHeaderData } from "../components/Page/PageHeader";
import { ITextWithHeaderData } from "../components/Page/TextWithHeader";
import { IColumnTextWithHeaderData } from "../components/Page/ColumnTextWithHeader";
import { IVideoCarouselData } from "../components/Carousel/VideoCarousel";
import { IServiceListData } from "../components/Services/ServiceList/ServiceList";

export enum PageDynamicComponents {
  BunnyLink = "BunnyLink",
  WordMarkLink = "WordMarkLink",
  PageFooter = "PageFooter",
  PageHeader = "PageHeader",
  PageDivider = "PageDivider",
  TextWithHeader = "TextWithHeader",
  ColumnTextWithHeader = "ColumnTextWithHeader",
  VideoCarousel = "VideoCarousel",
  ImageHero = "ImageHero",
}

export enum AboutDynamicComponents {
  AboutHero = "AboutHero",
  DividerHero = "DividerHero",
  Grid4 = "Grid4",
}

export enum CSDynamicComponents {
  ImageHeroLeft = "ImageHeroLeft",
  ImageHeroRight = "ImageHeroRight",
  ImageHeroSlim = "ImageHeroSlim",
  ImageHeroPopUpLeft = "ImageHeroPopUpLeft",
  ImageHeroPopUpRight = "ImageHeroPopUpRight",
  CaseStudyHero = "CaseStudyHero",
  CaseStudyFullBleedImage = "CaseStudyFullBleedImage",
  FilteredTileList = "FilteredTileList",
}

export enum CoreDynamicComponents {
  Container = "Container",
  Button = "Button",
}

export enum HomeDynamicComponents {
  HeroHeader = "HeroHeader",
  CaseStudyCarousel = "CaseStudyCarousel",
  BadgesHero = "BadgesHero",
}

export enum ServicesDynamicComponents {
  ServiceList = "ServiceList",
}

export type DynamicConponentsEnum =
  | CoreDynamicComponents
  | PageDynamicComponents
  | HomeDynamicComponents
  | ServicesDynamicComponents
  | CSDynamicComponents
  | AboutDynamicComponents;

export type DynamicComponent = {
  Page: PageDynamicComponents;
  CaseStudy: CSDynamicComponents;
  Home: HomeDynamicComponents;
  Services: ServicesDynamicComponents;
  Core: CoreDynamicComponents;
};
export interface IDynamicPageConfig {
  title: string;
  slug: string;
  route: string;
  data: IDynamicConfigElement[];
}

export interface IDynamicCaseStudyPageConfig extends IDynamicPageConfig {
  hero: ICaseStudyHeroData;
  tile: ICaseStudyTileData;
  isInHomeCarousel: boolean;
}

export interface IDynamicConfigElement {
  type: DynamicConponentsEnum;
  data?: IDynamicComponentData;
}

export type IDynamicComponentData =
  | IBaseComponentData
  | ITextWithHeaderData
  | IColumnTextWithHeaderData
  | IVideoCarouselData
  | IButtonData
  | IHomeLinkData
  | ICaseStudyCarouselData
  | IContainerData
  | IServiceListData
  | IBadgesHeroData
  | IPageHeaderData
  | ICaseStudyHeroData
  | IImageHeroData
  | IImageData
  | IImageHeroPopUpData
  | IAboutHeroData
  | IDividerHeroData
  | IFilteredTileListData;

export interface IBaseComponentData {
  className?: string;
  style?: CSSProperties;
}
export interface IContainerData extends IBaseComponentData {
  children: IDynamicConfigElement[];
}

export interface IImageData extends IBaseComponentData {
  src: string;
  alt?: string;
}
